import axios from "axios";
export const readyProducts = {
    state: {
        readyProducts: [],
        currentProduct: null,
    },
    getters: {
        getReadyProducts: state => {
            return state.readyProducts;
        },
        totalProducts: state => {
            return state.readyProducts.length;
        },
        getReadyProductById: (state) => (id) => {
           //console.log('getReadyProductById',state.readyProducts.find(item => item.id === id))
            return state.readyProducts.find(item => item.id === id);
        },

    },
    mutations: {

        setCurrentProduct(state, id) {
            state.currentProduct = id;
        },
        setReadyProducts(state, items) {
            state.readyProducts = items;
        },
        removeReadyProduct(state, itemId) {
            state.readyProducts = state.readyProducts.filter(item => item.id !== itemId);
        },
        addProduct(state, items) {
           //console.log('addProduct',items);
            state.readyProducts.push(items);
        },
        updateProduct(state, updatedProduct) {
            const index = state.readyProducts.findIndex(item => item.id === updatedProduct.id);
            if (index !== -1) {
                // If the product with the updated ID exists, update it in the readyProducts array
                state.readyProducts.splice(index, 1, updatedProduct);
            } else {
                // If the product doesn't exist, you can choose to handle it as per your application's requirement.
                console.error(`Product with ID ${updatedProduct.id} not found.`);
            }
        },
    },
    actions: {
        async loadReadyProducts({ commit ,rootState}) {
            try {
                const response = await axios.get('https://api.gh.programigo.com/api/products/ready', {
                    headers: { Authorization: `Bearer ${rootState.token}`}
                });
                const items = response.data;
                commit('setReadyProducts', items);
            } catch (error) {
                console.error('Error loading items:', error);
            }
        },
        async removeReadyProduct({commit, rootState }, itemId) {
            try {
                await axios.delete(`https://api.gh.programigo.com/api/products/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                commit('removeReadyProduct', itemId);
            } catch (error) {
                console.error('Error removing item:', error);
            }
        },
        async addProduct({commit, rootState}, data) {

            let fetchData = {
                "title": data.title,
                "content": data.description,
                "tags": data.tags,
                "type": data.type,
                "category": data.selectedCategory ? '/api/categories/' + data.selectedCategory : null,
                "ean": data.ean,
                "currency": data.currency,
                "priceUnit": data.priceUnit ? parseFloat(data.priceUnit.toString().replace(',', '.')) : null,
                "calculate": data.calculate,
                "price": data.price ? parseFloat(data.price.toString().replace(',', '.')) : null,
                "public": data.public,
                "oneChildren": data.oneChildren,
                "featureValue": data.featureValue,
                "featureUnit": data.featureUnit,
                "unit": data.unit ? '/api/units/' + data.unit : null,
                "unit2": data.unit2 ? '/api/units/' + data.unit2 : null,
            }

            try {
                await axios.post('https://api.gh.programigo.com/api/products',
                    fetchData,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addProduct', items);
                        commit('setResponseMessage', 'Produkt został dodany');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentProduct', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async copyProduct({commit}, data) {
            commit('addProduct', data);
            commit('setResponseMessage', 'Produkt został skopiowany');
            commit('setResponseStatus', 'success');
            commit('setCurrentProduct', data.id);
        },
        async updateProduct({commit, rootState}, item) {

            let data = item.item;
            let productId = item.productId

            let fetchData = {
                "title": data.title,
                "content": data.description,
                "tags": data.tags,
                "type": data.type,
                "category": data.selectedCategory ? '/api/categories/' + data.selectedCategory : null,
                "ean": data.ean,
                "currency": data.currency,
                "priceUnit": data.priceUnit ? parseFloat(data.priceUnit.toString().replace(',', '.')) : null,
                "calculate": data.calculate,
                "price": data.price ? parseFloat(data.price.toString().replace(',', '.')) : null,
                "public": data.public,
                "oneChildren": data.oneChildren,
                "featureValue": data.featureValue,
                "featureUnit": data.featureUnit,
                "unit": data.unit ? '/api/units/' + data.unit : null,
                "unit2": data.unit2 ? '/api/units/' + data.unit2 : null,
            }

           //console.log('updateProduct',data.unit, data.unit2, fetchData);

            try {
                await axios.put('https://api.gh.programigo.com/api/products/' + productId,
                    fetchData,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;

                       //console.log('updateProduct',items);

                        commit('updateProduct', items);
                        commit('setResponseMessage', 'Produkt został zaktualizowany');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentProduct', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
