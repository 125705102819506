<template>
  <v-text-field
      density="compact"
      label="Nazwa elementu"
      :modelValue="title"
      variant="outlined"
      @input="$emit('update:title', $event.target.value)"
  />
  <v-textarea
      label="Opis elementu"
      :modelValue="description"
      variant="outlined"
      @input="$emit('update:description', $event.target.value)"
  >
  </v-textarea>
  <v-text-field
      density="compact"
      label="Wskaźniki"
      :modelValue="tags"
      variant="outlined"
      @input="$emit('update:tags', $event.target.value)"
  />
</template>
<script>
export default {
  name: 'ProductDefaultForm',
  props: {
    title: String,
    description: String,
    tags: String,
  },
  emits: [
      'update:title',
      'update:description',
      'update:tags'
  ]
}
</script>

