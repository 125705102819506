import axios from "axios";
export const features = {
    state: {
        features: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getFeatures: state => {
            return state.features;
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setFeatures(state, items) {
            state.features = items;
        },
        addFeature(state, items) {
           //console.log('addFeature',items);
            state.features.push(items);
        },
        updateFeature(state, data) {
           //console.log('updateFeature',data, state.features);
            const index = state.features.findIndex(item => item.id === data.idFeature);
            state.features.splice(index, 1, {
                id: data.idFeature ,
                title: data.item.title,
                units: data.item.units
            });
        },
        removeFeature(state, itemId) {
            state.features = state.features.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadFeatures({commit, rootState }) {
            try {
                const response = await axios.get('https://api.gh.programigo.com/api/features',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setFeatures', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addFeature({commit, rootState }, data) {

            let units = [];
            data.units.filter((a) => {
                units.push('/api/units/' + a.id)
            })

            try {
                await axios.post('https://api.gh.programigo.com/api/features',
                    {
                        title: data.title,
                        units: units
                    },
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                       //console.log(items)
                        commit('addFeature', items);
                        commit('setResponseMessage', 'Cecha została dodana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateFeature({commit, rootState }, data) {
           //console.log('updateFeature', data, data.item.units)
            try {

                let units = [];
                data.item.units.filter((a) => {
                    units.push('/api/units/' + a.id)
                })

                await axios.put('https://api.gh.programigo.com/api/features/' + data.idFeature,
                    {
                        title: data.item.title,
                        units: units
                    },
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                       //console.log('updateFeature', data);
                        commit('updateFeature', data);
                        commit('setResponseMessage', 'Cecha została zaktualizowana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeFeature({commit, rootState }, itemId) {
            try {
                await axios.delete('https://api.gh.programigo.com/api/features/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeFeature', itemId);
                        commit('setResponseMessage', 'Cecha została usunięta');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
