<template>
  <v-card>

    <v-tabs
        v-model="tab"
        color="deep-purple-accent-4"
        align-tabs="center"
    >
      <v-tab value="1">Dane klienta</v-tab>
      <v-tab value="2">Szczegóły</v-tab>
      <v-tab value="3">Produkty</v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item value="1">
        <v-container>
          <v-row>
            <v-col>
              <v-card title="Dane klienta" text="">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Imie"
                          v-model="name"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Nazwisko"
                          v-model="lastName"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Ulica"
                          v-model="street"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Nr domu"
                          v-model="homeNumber"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Kod"
                          v-model="postcode"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Miasto"
                          v-model="city"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Telefon"
                          v-model="phone"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Email"
                          v-model="email"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-btn fab dark color="blue"
                             @click="saveCustomerData()">
                        Zapisz
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>
            <v-col>

            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item value="2">
        <v-container>
          <v-row>
            <v-col v-if="ticket !== null">
              <v-card title="Dane zamówienia">
                <v-card-item>
                  <strong>Data wprowadzenia: </strong>
                  <span v-if="ticket.dateAdd!=null">{{ new Date(ticket.dateAdd).toLocaleString()}}</span>
                  <span v-else>Brak danych</span>
                </v-card-item>
                <v-card-item>
                  <strong>Data modyfikacji: </strong>
                 <span v-if="ticket.dateModify!=null">{{ new Date(ticket.dateModify).toLocaleString()}}</span>
                 <span v-else>Brak danych</span>
                </v-card-item>
                <v-card-item>
                  <strong>Data ważności: </strong>
                  <VueDatePicker v-model="expirationDate" auto-apply locale="pl"
                                 month-name-format="long" :enable-time-picker="false" :format="format" model-type="yyyy-MM-dd" :teleport="true" @update:model-value="this.saveExpirationDate()"></VueDatePicker>
                </v-card-item>
              </v-card>
              <v-row class="mt-3">
                <v-col>
                  <v-btn prepend-icon="mdi-file-pdf-box"
                         target="_blank" width="100%" color="red" size="x-large" stacked variant="elevated">
                    Oferta
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn prepend-icon="mdi-file-pdf-box"
                         target="_blank" width="100%" color="red" size="x-large" stacked variant="elevated">
                    Oferta PRO
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn prepend-icon="mdi-file-tree-outline" width="100%"
                         color="blue" size="x-large" stacked variant="elevated">
                    Wycena
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item value="3">

        {{ selectedProducts }}

      </v-window-item>

    </v-window>

  </v-card>
  {{ ticket.customers }}
</template>

<script>
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "TicketDetail",
  components: {VueDatePicker: VueDatePicker},
  props: ['ticketId'],
  data() {
    return {
      ticket: null,
      selectedProducts: null,
      tab: null,
      name: "",
      lastName: "",
      street: "",
      homeNumber: "",
      postcode: "",
      city: "",
      phone: "",
      email: "",
      expirationDate: "",
    }
  },
  methods: {
    async saveExpirationDate() {

      console.log(this.expirationDate);
      await this.$store.dispatch('updateTicket', {
        item: {
          expirationDate: this.expirationDate
        },
        idTicket: this.ticketId,
        idCustomers: this.ticket.customers !== undefined ? this.ticket.customers.id : null,
      });
      //this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    async saveCustomerData() {
      let today = new Date();
      let day = String(today.getDate()).padStart(2, '0');
      let month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let year = today.getFullYear();
      let hour = today.getHours();
      let minutes = today.getMinutes();
      let seconds = today.getSeconds();

      today = year + '-' + month + '-' + day +' '+hour+':'+minutes+':'+seconds;
      // console.log('saveCustomerData',this.ticket.customers);
      await this.$store.dispatch('updateTicket', {
        item: {
          title: this.title,
          math: JSON.stringify(this.math),
          selectedProducts: JSON.stringify(this.selectedProducts),
          selectedProject: JSON.stringify(this.selectedProject),
          tree: JSON.stringify(this.tree),
          dateModify: today,
          customers: {
            id: this.ticket.customers !== undefined ? "/api/customers/" + this.ticket.customers.id : null,
            name: this.name,
            lastName: this.lastName,
            phone: this.phone,
            email: this.email,
            addresses: {
              id: this.ticket.customers !== undefined ? this.ticket.customers.addresses.id : null,
              street: this.street,
              homeNumber: this.homeNumber,
              postcode: this.postcode,
              city: this.city
            }
          }
        },
        idTicket: this.ticketId,
        idCustomers: this.ticket.customers !== undefined ? this.ticket.customers.id : null,
      });
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    }

  },
  computed: {
    ...mapGetters(['getTicketById', 'getToken'])
  },
  created() {

    this.ticket = this.getTicketById(this.ticketId);
    this.title = this.ticket.title;
    this.expirationDate = this.ticket.expirationDate ? this.ticket.expirationDate.split('T')[0] : '';
    this.title = this.ticket.title;
    this.selectedProducts = JSON.parse(this.ticket.selectedProducts);
    this.selectedProject = JSON.parse(this.ticket.selectedProject);
    this.tree = JSON.parse(this.ticket.tree);
    this.math = JSON.parse(this.ticket.math);
    if (this.ticket.customers) {
      this.name = this.ticket.customers.name;
      this.lastName = this.ticket.customers.lastName;
      this.phone = this.ticket.customers.phone;
      this.email = this.ticket.customers.email;
      if (this.ticket.customers.addresses) {
        this.street = this.ticket.customers.addresses.street;
        this.homeNumber = this.ticket.customers.addresses.homeNumber;
        this.postcode = this.ticket.customers.addresses.postcode;
        this.city = this.ticket.customers.addresses.city;
      }
    }
  }
}
</script>

<style scoped>

</style>
