<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
    </v-col>
  </v-row>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          single-line
          hide-details
          density="compact"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        density="compact"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="tickets.tickets"
        :loading="loading"
        :search="search"
        class="elevation-1"
        item-value="id"
    >

      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.selectable.id }}</td>
          <td>{{ item.selectable.title }}</td>
          <td align="end">
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="green">
              Zobacz
              <v-dialog
                  v-model="dialogTicket[item.selectable.id]"
                  activator="parent"
                  fullscreen
                  persistent
              >
                <v-card>
                  <v-card-title>
                    ID: {{ item.selectable.id }}
                    <v-btn icon @click="taskDialogTicketClose(item.selectable.id)" class="float-end" variant="text"
                           density="compact">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <TicketDetail v-if="dialogTicket[item.selectable.id] === true"
                                :ticketId="item.selectable.id"></TicketDetail>
                </v-card>
              </v-dialog>
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="blue"
            :to="'/ticket/' + item.selectable.id">
              Edytuj
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="red"
                   @click="fetchDeleteTicket(item.selectable.id)">
              Usuń
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script>

import {mapActions, mapGetters, mapState} from "vuex";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import TicketDetail from "@/views/tickets/TicketDetail.vue";


export default {
  name: 'TicketsView',
  components: {TicketDetail, BreadcrumbsComponent},
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      title: '',

      breadcrumbs: [
        {
          title: 'Zlecenia',
          disabled: true,
        },

      ],
      dialogTicket: [],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],

      features: {},
      selectedFeatures: [],

    };
  },
  computed: {
    ...mapState(["tickets"]),
    ...mapGetters(["isLoggedIn", 'getToken','getRoles()'])
  },
  created() {
    this.fetchTickets();
  },
  methods: {
    ...mapActions(['loadTickets']),
    async fetchTickets() {
      await this.loadTickets();
    },
    async fetchDeleteTicket(idTicket) {
      await this.$store.dispatch('removeTicket', idTicket);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    taskDialogTicketClose(idTicket){
      console.log(idTicket)
      console.log(this.dialogTicket[idTicket])
      this.dialogTicket = [];



    }
  }
}
</script>
