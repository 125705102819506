<template>
  <div class="position-absolute" style="width:100%; ">
    <v-row>
      <v-col>
        <BreadcrumbsComponent :items="breadcrumbs"/>
      </v-col>
      <v-col style="z-index: 2;">
        <v-btn
            color="green"
            style="margin-top:6px; "
            class="float-start mx-1"
            size="x-small"
            prepend-icon="mdi-content-save"
        >
          Zapisz

          <v-dialog
              v-model="dialog"
              activator="parent"
              width="auto"
          >
            <v-card>
              <v-card-text>
                <v-text-field style="width: 550px;" label="Nazwa" variant="outlined"
                              v-model="titleProject"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="saveProject('project')">Zapisz</v-btn>
                <v-btn color="primary" @click="dialog = false">Zamknij</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>



        <v-btn
            color="green"
            style="margin-top:6px;"
            class="float-start mx-1"
            size="x-small"
            prepend-icon="mdi-refresh"
            @click="getMath"
        >
          Przelicz
        </v-btn>

        <v-btn
            color="green"
            style="margin-top:6px;"
            class="float-start mx-1"
            size="x-small"
            prepend-icon="mdi-refresh"
            @click="updateProducts"
        >
          Aktualizuj materiały
        </v-btn>
        <v-btn
            color="green"
            style="margin-top:6px;"
            class="float-start mx-1"
            size="x-small"
            prepend-icon="mdi-refresh"
            @click="updateProducts(all)"
        >
          Aktualizuj materiały (Wszędzie)
        </v-btn>
        <v-select
            class="x-small float-start mx-1 mt-1"
            :items="[{id: '0', title: 'Pionowo'},{id: '1', title: 'Poziomo'}]"
            item-text="title"
            item-value="id"
            v-model="treeOrientation"
            variant="outlined"
            density="compact"
            hide-details
        >
        </v-select>
      </v-col>

    </v-row>
  </div>
  <v-container style="z-index:0;">
    <div v-if="selected !== null" id="scene">
      <div class="">
        <div v-if="productsTree !== null" class="text-center tree" ref="headRef" style="position:relative;">
          <blocks-tree :data="productsTree.valueOf()"
                       :horizontal="treeOrientation==='1'"
                       class=" scrolled"
                       :collapsable="collapsable"
                       @change="getMath">
            <template #node="{data}">

              <v-card v-if="data.id === 0" color="blue" >
                <v-btn
                    color="green"
                    style="margin-top:6px;"
                    class="float-end ma-3"
                    size="x-small"
                    prepend-icon="mdi-plus-box"
                    @click="tryAddLeaf(data.id,productsTree)">Dodaj
                </v-btn>
              </v-card>
              <v-card v-if="data.id !== 0" :style="'min-width:250px; position:relative; background-color:' + checkItem(data.id).color + '; color:' + checkItem(data.id).colorText">
                <v-btn
                   prepend-icon="mdi-palette" variant="text"
                   size="x-small"
                >
                  <v-tooltip activator="parent" >
                    Wybierz kolor karty
                  </v-tooltip>

                  <v-dialog
                      v-model="dialogColor[data.id]"
                      activator="parent"
                      width="auto"
                  >
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            Tło
                            <v-color-picker
                                class="ma-2"
                                show-swatches
                                swatches-max-height="400px"
                                @update:modelValue="updateTree($event, data.id, 'color')"
                                :modelValue="checkItem(data.id).color"
                            ></v-color-picker>
                          </v-col>
                          <v-col>
                            Tekst
                            <v-color-picker
                                class="ma-2"
                                show-swatches
                                swatches-max-height="400px"
                                @update:modelValue="updateTree($event, data.id, 'colorText')"
                                :modelValue="checkItem(data.id).colorText"
                            ></v-color-picker>
                          </v-col>
                        </v-row>

                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" block @click="dialogColor[data.id] = false">Zamknij</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-btn>


                <v-btn :to="'/products/tree2/' + data.element" v-if="data.element !== undefined" class="position-absolute" style="top:-5px; right: -5px; width:20px; height: 20px; background-color: #910000;">
                  <v-tooltip activator="parent" location="end">
                    Element drzewa
                  </v-tooltip>
                </v-btn>
                <h1 class="pa-4" style="font-size: 25pt;">{{ checkItem(data.id).label }}</h1>
                <v-card-item> <!-- v-if="checkItem(data.id) && checkItem(data.id).type === null  " -->
                  <v-select
                      class="x-small"
                      variant="outlined"
                      density="compact"
                      :items="productType"
                      item-title="label"
                      item-value="value"
                      hide-details
                      @update:modelValue="updateTree($event, data.id, 'type')"
                      :modelValue="checkItem(data.id).type"
                  ></v-select>
                </v-card-item>
                <v-card-item v-if="checkItem(data.id).type === 'category'">
                  <v-text-field
                      class="mt-2"
                      variant="outlined"
                      label="Nazwa elementu"
                      density="compact"
                      @input="updateTree($event, data.id,'label')"
                      :modelValue="checkItem(data.id).label"
                  ></v-text-field>
                </v-card-item>
                <v-card-item v-if="checkItem(data.id).type === 'build'">
                  <v-text-field
                      class="mt-2"
                      label="Nazwa półproduktu"
                      variant="outlined"
                      density="compact"
                      @input="updateTree($event, data.id,'label')"
                      :modelValue="checkItem(data.id).label"
                  ></v-text-field>
                  <v-autocomplete
                      class="x-small"
                      density="compact"
                      variant="outlined"
                      label="Wybierz kategorię"
                      :items="categories.categories"
                      item-title="title"
                      item-value="id"
                      @update:modelValue="updateTree($event, data.id,'categories')"
                      :modelValue="checkItem(data.id).categories"
                  ></v-autocomplete>

                  <!-- Cechy -->
                  <div v-if="categoryFeatures[data.id] && categoryFeatures[data.id].length > 0">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-title>Cechy</v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <div class="v-sheet v-sheet--border v-sheet--rounded overflow-hidden pa-2">
                            <template v-for="item in categoryFeatures[data.id]"
                                      :key="item.id">
                              <v-row>
                                <v-col cols="6" class="pb-0 text-body-2">{{ item.title }} [{{ data.id }} {{
                                    item.id
                                  }}]
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="1">
                                  <v-checkbox  hide-details density="compact"
                                               @change="updateTree($event, data.id, 'input', item.id)"
                                               :modelValue="checkItem(data.id, item.id).input"
                                               style="width: 160px;"
                                  >
                                    <v-tooltip activator="parent" location="start">Sprzedawca może wprowadzać tą wartość</v-tooltip>
                                  </v-checkbox>
                                </v-col>
                                <v-col cols="2" class="pb-0">
                                  <v-select
                                      class="x-small"
                                      variant="outlined"
                                      density="compact"
                                      hide-details
                                      :items="item.units"
                                      item-value="id"
                                      @update:modelValue="updateTree($event, data.id, 'unit', item.id)"
                                      :modelValue="checkItem(data.id, item.id).unit"
                                  ></v-select>
                                  <!--  -->
                                </v-col>
                                <v-col cols="6" class="pb-0">
                                  <v-text-field
                                      class="x-small"
                                      density="compact"
                                      hide-details
                                      variant="outlined"
                                      @change="updateTree($event, data.id, 'feature', item.id)"
                                      :modelValue="checkItem(data.id, item.id).chip"
                                      v-model="clear"
                                      @contextmenu="show($event, data.id, item.id)">
                                  </v-text-field>
                                  <v-menu
                                      @change="showMenu(data.id, item.id)"
                                      :modelValue="getMenu(data.id, item.id)"
                                      :style="'left:' + x + 'px; top:' + y + 'px;'"
                                      abolute
                                      offset-y
                                      :close-on-content-click="false"
                                  >
                                    <v-list>
                                      <template v-for="feature in data.features" :key="feature.id">
                                        <v-list-item>
                                          <v-list-item-title
                                              @click="addChip(sF1,sF2,feature.label,data.id +'-'+ feature.id +'-W')">
                                            {{ feature.label }}
                                          </v-list-item-title>
                                        </v-list-item>
                                      </template>
                                    </v-list>

                                    <v-list>
                                      <template v-for="variable in variables.variables" :key="variable.id">
                                        <v-list-item>
                                          <v-list-item-title
                                              @click="addChip(sF1,sF2,variable.title,'Z_' + variable.id)">
                                            {{ variable.title }}
                                          </v-list-item-title>
                                        </v-list-item>
                                      </template>
                                    </v-list>

                                    <v-list>
                                      <template v-for="(item, index) in data.children" :key="index">

                                        <v-list-group :value="item.label" no-action>
                                          <template v-slot:activator="{ props }">
                                            <v-list-item
                                                v-bind="props"
                                                :title="item.label"
                                            ></v-list-item>
                                          </template>

                                          <v-list-item v-for="feature in item.features" :key="feature.id">
                                            <v-list-item-title
                                                @click="addChip(sF1,sF2,item.label + ' - ' + feature.label,item.id +'_'+ feature.id +'_W')">
                                              {{ feature.label }} {{ item.id + '-' + feature.id + '-W' }}
                                            </v-list-item-title>
                                          </v-list-item>
                                          <v-list-item>
                                            <v-list-item-title
                                                @click="addChip(sF1,sF2,item.label + ' - Cena',item.id +'_??_C')">
                                              {{ item.label }} Cena
                                            </v-list-item-title>
                                          </v-list-item>
                                          <v-list-item>
                                            <v-list-item-title
                                                @click="addChip(sF1,sF2,item.label + ' - Cena jednostki', item.id +'_??_J')">
                                              {{ item.label }} Cena
                                              jednostki
                                            </v-list-item-title>
                                          </v-list-item>

                                          <template v-for="(item, index) in item.children" :key="index">
                                            <v-list-group :value="item.label" no-action>
                                              <template v-slot:activator="{ props }">
                                                <v-list-item
                                                    v-bind="props"
                                                    :title="item.label"
                                                ></v-list-item>
                                              </template>
                                              <v-list-item v-for="feature in item.features" :key="feature.id">
                                                <v-list-item-title
                                                    @click="addChip(sF1,sF2,item.label + ' - ' + feature.label,item.id +'_'+ feature.id +'_W')">
                                                  {{
                                                    item.label
                                                  }} - {{ feature.label }}
                                                </v-list-item-title>
                                              </v-list-item>
                                              <v-list-item>
                                                <v-list-item-title
                                                    @click="addChip(sF1,sF2,item.label + ' - Cena',item.id +'_??_C')">
                                                  {{ item.label }} Cena
                                                </v-list-item-title>
                                              </v-list-item>
                                              <v-list-item>
                                                <v-list-item-title
                                                    @click="addChip(sF1,sF2,item.label + ' - Cena jednostki', item.id +'_??_J')">
                                                  {{ item.label }} Cena
                                                  jednostki
                                                </v-list-item-title>
                                              </v-list-item>
                                              <template v-for="(item, index) in item.children" :key="index">
                                                <v-list-group :value="item.label" no-action>
                                                  <template v-slot:activator="{ props }">
                                                    <v-list-item
                                                        v-bind="props"
                                                        :title="item.label"
                                                    ></v-list-item>
                                                  </template>
                                                  <v-list-item v-for="feature in item.features" :key="feature.id">
                                                    <v-list-item-title
                                                        @click="addChip(sF1,sF2,item.label + ' - ' + feature.label,item.id +'_'+ feature.id +'_W')">
                                                      {{
                                                        item.label
                                                      }} - {{ feature.label }}
                                                    </v-list-item-title>
                                                  </v-list-item>
                                                  <v-list-item>
                                                    <v-list-item-title
                                                        @click="addChip(sF1,sF2,item.label + ' - Cena',item.id +'_??_C')">
                                                      {{ item.label }} Cena
                                                    </v-list-item-title>
                                                  </v-list-item>
                                                  <v-list-item>
                                                    <v-list-item-title
                                                        @click="addChip(sF1,sF2,item.label + ' - Cena jednostki', item.id +'_??_J')">
                                                      {{ item.label }} Cena
                                                      jednostki
                                                    </v-list-item-title>
                                                  </v-list-item>
                                                </v-list-group>
                                              </template>
                                            </v-list-group>
                                          </template>
                                        </v-list-group>
                                      </template>
                                      <v-list-item>
                                        <v-list-item-title
                                            @click="addChip(sF1,sF2,'Wybrany element - Cena','??_??_C')">
                                          Wybrany element - Cena
                                        </v-list-item-title>
                                      </v-list-item>
                                      <v-list-item>
                                        <v-list-item-title
                                            @click="addChip(sF1,sF2,'Wybrany element - Cena jednostki', '??_??_J')">
                                          Wybrany element - Cena
                                          jednostki
                                        </v-list-item-title>
                                      </v-list-item>
                                      <v-list-item>
                                        <v-list-item-title
                                            @click="addChip(sF1,sF2,'Suma poniższych - Cena jednostki', '00_00_J')">
                                          Sumuj wszystkie poniżej - Cena Jednostki
                                        </v-list-item-title>
                                      </v-list-item>
                                      <v-list-item>
                                        <v-list-item-title
                                            @click="addChip(sF1,sF2,'Suma poniższych - Cena jednostki', '00_00_C')">
                                          Sumuj wszystkie poniżej - Cena
                                        </v-list-item-title>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-col>

                                <template v-if="chipsMath[data.id]">
                                <v-col cols="3" class="pb-0" v-if="chipsMath[data.id][item.id] !== []">
                                  <v-tooltip
                                      activator="parent"
                                      location="top"
                                  >{{ chipsMath[data.id][item.id] }}</v-tooltip>
                                  {{ checkItem(data.id, item.id).value }}
                                </v-col>
                                </template>
                              </v-row>
                              <v-row v-if="chipsMath">
                                <v-col cols="12" class="pt-0" v-if="chipsMath[data.id]">
                                  <template v-if="chips[data.id]">
                                    <draggable v-model="chips[data.id][item.id]"
                                               @change="change($event,data.id,item.id)">
                                      <v-chip size="x-small" closable
                                              v-for="(chip,index) in chips[data.id][item.id]"
                                              :key="chip"
                                              @click:close="remove(data.id,item.id, index)"
                                      >
                                        {{ chip }}
                                      </v-chip>
                                    </draggable>
                                  </template>
                                </v-col>
                              </v-row>
                            </template>
                          </div>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-card-item>
                <v-card-item v-if="checkItem(data.id).type === 'ready'">

                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-title style="height:20px; font-size: 10px; padding: 5px; min-height:20px;">Szczegóły</v-expansion-panel-title>
                      <v-expansion-panel-text>

                        <v-text-field
                            class="mt-2"
                            variant="outlined"
                            label="Nazwa własna"
                            density="compact"
                            @input="updateTree($event, data.id,'label')"
                            :modelValue="checkItem(data.id).label"
                        ></v-text-field>

                        <v-autocomplete
                            class="x-small"
                            density="compact"
                            variant="outlined"
                            label="Wybierz kategorię"
                            :items="categories.categories"
                            item-title="title"
                            item-value="id"
                            @update:modelValue="updateTree($event, data.id,'categories')"
                            :modelValue="checkItem(data.id).categories"
                            @click="getProductsFromCategory(checkItem(data.id).categories)"
                        ></v-autocomplete>

                        <v-autocomplete
                            v-if="checkItem(data.id).categories"
                            density="compact"
                            label="Produkt"
                            variant="outlined"
                            class="x-small"
                            :items="readyProductsFromCategory"
                            item-value="id"
                            item-title="title"
                            @update:modelValue="updateTree($event, data.id,'product')"
                            :modelValue="checkItem(data.id).product"
                        ></v-autocomplete>

                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>

                </v-card-item>
                <v-avatar v-if="data.image"
                          class="ma-3"
                          size="125"
                          rounded="0"
                >
                  <v-img :src="'https://api.gh.programigo.com/'+data.image"></v-img>
                </v-avatar>

                <div v-if="data.images">
                  <v-img style="width:50px; float:left; " v-for="image in data.images" :key="image"
                         :src="'https://api.gh.programigo.com/'+image"></v-img>
                </div>
                <br>
                <div v-if="data.files">
                  <v-btn style="float:left; " v-for="file in data.files" icon="mdi-file" :key="file" target="_blank"
                         :href="'https://api.gh.programigo.com/'+file"></v-btn>
                </div>


                <v-card v-if="data.product && checkItem(data.id).type === 'ready' || data.id === 0" flex
                        class="position-relative product-detail"
                        style="width:450px;"
                >
                  <v-card-title>
                    {{ data.title }}
                  </v-card-title>
                  <v-card-text>
                    <div v-for="item in data.features" :key="item.id">
                      <v-code v-if="checkRole(['ROLE_BUILD_TREE','ROLE_ADMIN']) === true && item.mathTask ">M:
                        {{ item.mathTask }}
                      </v-code>
                      <div v-if="item.allowValue === false">

                        <div v-if="item.type === 1">
                          <strong>{{ item.label }}</strong>:
                          <span v-if="item.allowValue === false">{{ item.value }}</span>{{ item.meanUnit }}
                        </div>

                        <div v-else-if="item.type === 2">
                          <strong>{{ item.label }} M</strong>:
                          {{ item.value }} {{ item.meanUnit }}
                        </div>
                      </div>
                      <v-text-field
                          class="mt-2"
                          v-if="item.allowValue === true"
                          density="compact"
                          variant="outlined"
                          @input="updateMyProp($event.target.value, [data.product, item.id, data.id])"
                          :label="item.label + ' ' +item.meanUnit + '[ od ' + item.min +' do ' + item.max +']'"
                          hide-details="auto"
                      ></v-text-field>
                    </div>
                  </v-card-text>
                  <v-card-text v-if="data.price" flex>
                    <!--                      <v-code style="padding:1px; font-size: 9px;"
                                                  v-if="checkRole(['ROLE_BUILD_TREE','ROLE_ADMIN']) === true"
                                          >W: #{{ data.product }}-??-C
                                          </v-code>-->
                    <strong style="display:block; position:relative;" v-if="data.type === 'ready'">
                      <span v-show="data.price">Cena całkowita: {{ data.price }} {{ data.priceLabelUnit }}</span><br>
                      <span v-show="data.priceUnit">Cena Jednostki: {{ data.priceUnit }} {{
                          data.priceReadyLabelUnit
                        }}</span> <br>
                      <div v-for="itemf in data.features" :key="itemf.id">
                        <span v-show="itemf.value">{{ itemf.label }}: {{ itemf.value }}</span><br>
                      </div>
                    </strong>

                  </v-card-text>


                </v-card>

                <v-card-item>

                  <v-btn
                      color="green"
                      style="margin-top:6px;"
                      class="float-end ms-1"
                      size="x-small"
                      prepend-icon="mdi-plus-box"
                      @click="tryAddLeaf(data.id,productsTree)">Dodaj
                  </v-btn>
                  <v-btn
                      color="blue"
                      style="margin-top:6px;"
                      class="float-end ms-1"
                      size="x-small"
                      prepend-icon="mdi-alpha-x-box"
                     >Otwórz

                    <v-dialog
                        v-model="dialogProduct[data.product]"
                        activator="parent"
                        width="1000"
                    >
                      <v-card-title>
                        <v-btn icon @click="dialogProductNew = false" class="float-end" variant="text"
                               density="compact">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card>
                        <ProductsSingleDialogView :productId="data.product"></ProductsSingleDialogView>
                      </v-card>
                    </v-dialog>

                  </v-btn>

                  <v-menu class="float-end ms-1">
                    <template v-slot:activator="{ props: menu }">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props: tooltip }">
                          <v-btn
                              color="blue"
                              v-bind="mergeProps(menu, tooltip)"
                              size="x-small"
                              class="float-end ms-1"
                              style="margin-top:6px;"
                              prepend-icon="mdi-format-list-checkbox"
                          >
                            Opcje
                          </v-btn>
                        </template>
                        <span>Zobacz wszystkie opcje</span>
                      </v-tooltip>
                    </template>
                    <v-list>
                      <v-list-item v-if="data.element" @click="updateElement(data.id,data.element)">
                        <v-list-item-title class="title">Aktualizuj element</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="data.element" @click="saveProject('element', data.id, data.element)">
                        <v-list-item-title class="title">Zapisz element</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="!data.element">
                        <v-list-item-title class="title">Zapisz element</v-list-item-title>
                        <v-dialog
                            v-model="dialogTree[data.id]"
                            activator="parent"
                            width="auto"
                        >
                          <v-card>
                            <v-card-text>
                              <v-text-field style="width: 550px;" label="Nazwa elementu" variant="outlined"
                                            v-model="titleProjectTree[data.id]"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="primary" @click="saveProject('element',data.id )">Zapisz</v-btn>
                              <v-btn color="primary" @click="dialogTree[data.id] = false">Zamknij</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item>


                      <v-list-item @click="deleteNode(data,productsTree)">
                        <v-list-item-title class="title">Usuń</v-list-item-title>
                      </v-list-item>
                      <v-list-item >
                        <v-list-item-title class="title">Dodaj załącznik</v-list-item-title>
                        <v-dialog
                            v-model="dialogTreeUploadFile[data.id]"
                            activator="parent"
                            width="auto"
                        >
                          <v-card>
                            <form @submit="uploadFile">
                              <v-card-text>
                                <input type="file" ref="fileInput"/>
                                <input type="hidden" ref="productTitle" :value="checkItem(data.id).label"/>
                                <input type="hidden" ref="productId" :value="data.product"/>
                                <input type="hidden" ref="idTree" :value="data.id"/>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn color="primary" type="submit" @click="uploadFile">Wgraj</v-btn>
                                <v-btn color="primary" @click="dialogTreeUploadFile[data.id] = false">Zamknij</v-btn>
                              </v-card-actions>
                            </form>
                          </v-card>
                        </v-dialog>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title class="title">Podepnij element</v-list-item-title>
                        <v-dialog
                            v-model="dialogTreeUpload[data.id]"
                            activator="parent"
                            width="auto"
                        >
                          <v-card>
                            <v-card-text>
                              <v-select
                                  class="x-small"
                                  variant="outlined"
                                  density="compact"
                                  hide-details
                                  :items="projectsElements.projectsElements"
                                  item-value="id"
                                  item-title="title"
                                  @update:modelValue="loadTree($event)"
                              ></v-select>

                            </v-card-text>
                            <v-card-actions>
                              <v-btn color="primary" @click="loadTreeDown(data.id)">Wczytaj</v-btn>
                              <v-btn color="primary" @click="dialogTreeUpload[data.id] = false">Zamknij</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item>


                    </v-list>
                  </v-menu>

                  <!--                  <label class="form-switch form-switch-lg">
                     <input class="form-check-input"
                            type="radio"
                            :name="'radio_' + checkParent(data.id)" :value="data.id"
                            :checked="selected.indexOf(data.id) > -1"
                            @change="toggleSelect(data,$event.target.checked)"
                     ></label>-->
                </v-card-item>
              </v-card>

            </template>
          </blocks-tree>
        </div>
        <div v-else-if="productsTree === null">
          <div class="text-center">
            <v-progress-circular
                indeterminate
                color="red"
                :size="70"
                :width="7"
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import {generateUniqueId, addMath, rozdzielDzialanie} from '@/views/tree/js/main.js'


import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';
import * as panzoom from "panzoom";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import ProductsSingleDialogView from "@/views/products/ProductsSingleDialogView.vue";
import {mergeProps} from 'vue'
import {VueDraggableNext} from 'vue-draggable-next'


export default {
  name: "ProductTree2",
  components: {
    draggable: VueDraggableNext,
    ProductsSingleDialogView,
    BreadcrumbsComponent,
    'blocks-tree': VueBlocksTree,
  },

  data() {
    return {

      dialog: false,
      error: [],

      dialogColor: [],
      blockColor: [],

      loadTreeData: null,

      readyProductsFromCategory: [],

      items: ['Gaming', 'Programming', 'Vue', 'Vuetify'],
      model: ['Vuetify'],
      search: null,

      duplicated: [],

      titleProject: '',
      titleProjectTree: [],
      dialogTree: [],
      dialogTreeUpload: [],
      dialogTreeUploadFile: [],

      clear: '',
      dialogProduct: [],
      x: 0,
      y: 0,
      searchTerm: "",
      chips: [],
      chipsMath: [],
      dialogCalc: [],
      itemSelect: [],
      itemSelect_: "",
      sF1: null,
      sF2: null,
      productType: [
        {
          value: 'category',
          label: 'Wirtualny',
        },
        {
          value: 'build',
          label: 'Produkt własny',
        },
        {
          value: 'ready',
          label: 'Produkt handlowy',
        },
      ]
      ,

      changeTree: null,

      typeProject: null,
      breadcrumbs: [
        {
          title: 'Projekt',
          disabled: true,
        },
        {
          title: this.typeProject,
          disabled: true,
        },
      ],

      categoryFeatures: [],

      math: [],
      productsTree: null,
      selected: [],
      treeOrientation: '0',
      collapsable: false,
      menu: [],
      selection: null,
      currentTag: null,

      /*const toggleSelect = (node, isSelected) => {
        isSelected ? selected.value.push(node.some_id) : selected.value.splice(selected.value.indexOf(node.some_id), 1);
        if(node.children && node.children.length) {
          node.children.forEach(ch=>{
            toggleSelect(ch,isSelected)
          })
        }
      }*/

      input: []

    }
  },

  mounted() {
    this.fetchReadyProducts();


    {
      // just grab a DOM element
      var element = document.querySelector('#scene')

      // And pass it to panzoom
      panzoom(element, {
        filterKey: function (/* e, dx, dy, dz */) {
          // don't let panzoom handle this event:
          return true;
        }
      });
    }

    this.getListTree();
    this.getMath();
  },
  created() {
    this.fetchReadyProducts();
    this.fetchCategories();
    this.fetchProjects();
    this.fetchProjectsElements();
    this.fetchVariables();
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getUser", "getRoles", 'getProjectElementsById', 'getVariables','getVariableById','getCategoryById', 'getProjectById']),
    ...mapState(["readyProducts", "categories", 'projectsElements', 'variables'])
  },
  methods: {
    ...mapActions(['loadReadyProducts', 'loadCategories', 'loadProjects', 'loadProjectsElements', 'loadVariables']),
    async fetchReadyProducts() {
      await this.loadReadyProducts();
    },
    async fetchCategories() {
      await this.loadCategories();
    },
    async fetchProjects() {
      await this.loadProjects();
    },
    async fetchProjectsElements() {
      await this.loadProjectsElements();
    },
    async fetchVariables() {
      await this.loadVariables();
    },

    async getProductsFromCategory(idCategory) {
      this.readyProductsFromCategory = [];
      this.readyProducts.readyProducts.filter((product) => {
        if (idCategory === product.groupId) {
          this.readyProductsFromCategory.push({
            'id': product.id,
            'title': product.title,
          });
        }
      });
    },

    change(e, idProduct, idFeature) {
      let array = this.chipsMath[idProduct][idFeature];
      const element = array.splice(e.moved.oldIndex, 1)[0];
      array.splice(e.moved.newIndex, 0, element);
      this.chipsMath[idProduct][idFeature] = array;

      addMath.call(this, this.chipsMath, idProduct, idFeature);
      this.getMath();
    },
    dragStart() {
      ////console.log('start');
      /*      if (this.tags[this.selection]) this.currentTag = this.tags[this.selection].name;
            else this.currentTag = null;*/
    },
    dragEnd() {
      ////console.log('end');
      /*      var self = this;
            if (this.currentTag) {
              this.tags.forEach((x, i) => {
                if (x.name === self.currentTag) self.selection = i;
              });
            }*/

    },

    mergeProps,
    showMenu(a, b) {
      ////console.log(this.menu[a][b]);
      if (this.menu[a] === undefined) {
        this.menu[a] = [];
        this.menu[a][b] = true;
      }
      //if(this.math[a][b] === false){
      this.menu[a][b] = true;
      //}

    },
    getMenu(a, b) {
      if (this.menu[a] === undefined) {
        this.menu[a] = [];
        this.menu[a][b] = false;
      }
      return this.menu[a][b];
    },

    show(e, id1, id2) {
      e.preventDefault()
      this.x = e.clientX
      this.y = e.clientY
      this.sF1 = id1;
      this.sF2 = id2;

      if (this.menu[id1][id2] === true) {
        this.menu[id1][id2] = false;
      }

      this.$nextTick(() => {
        if (this.menu[id1] === undefined) {
          this.menu[id1] = [];
        }
        this.menu[id1][id2] = true;
      })

    },

    // Aktualizacja drzewa
    async updateTree(event, id, type, id2 = null) {

      ////console.log('updateTree', event, id, type, id2);

      if (id) {
        //Dodajemy element do drzewa
        let treeElement = this.searchProductById(this.productsTree, id);
        if (type === 'label') {
          treeElement.label = event.target.value;
        }
        if (type === 'color') {
          treeElement.color = event;
          this.addColorToChildren(treeElement, event);
        }
        if (type === 'colorText') {
          treeElement.colorText = event;
          this.addColorTextToChildren(treeElement, event);
        }
        if (type === 'type') {
          treeElement.type = event;
        }
        if (type === 'categories') {
          treeElement.categories = event;
          this.getCategoryFeatures(event, id);
        }
        if (type === 'product') {
          treeElement.product = event;

          let product = await this.getById(event);

          if (product) {
            treeElement.title = product.title;
            treeElement.label = product.title;
          }

          treeElement.price = product.price;
          treeElement.calculate = product.calculate;
          treeElement.category = product.category;
          treeElement.children = product.children;
          treeElement.content = product.content;
          treeElement.converter = product.converter;
          treeElement.currency = product.currency;
          treeElement.features = product.features;
          treeElement.ean = product.ean;
          treeElement.product = product.id;
          treeElement.isInputValue = product.isInputValue;
          treeElement.math = product.math;
          treeElement.oneChildren = product.oneChildren;
          treeElement.parent = product.parent;
          treeElement.price = product.price;
          treeElement.priceUnit = product.priceUnit;
          treeElement.public = product.public;
          treeElement.type = product.type;
          treeElement.unit = product.unit;
        }

        if (id2 !== null) {
          let result2;
          if (treeElement.features && treeElement.features.length > 0) {
            result2 = treeElement.features.find((g) => g.id === id2);
          } else {
            result2 = null;
          }

          ////console.log(result2);


          let content = {};

          if (type === 'math') {
            content = {
              id: id2,
              math: event,
              value: null,
            };
            if (result2) {
              result2.math = event;
              result2.value = null;
            }
          }

          if (type === 'unit') {
            content = {
              id: id2,
              unit: event,
            };
            if (result2) {
              result2.unit = event;
            }
          }

          if (type === 'feature') {
            if (typeof event !== "string") {
              content = {
                id: id2,
                math: event.target.value,
                value: event.target.value,
                chip: ''
              };
            } else {
              content = {
                id: id2,
                math: event,
                value: event,
                chip: ''
              };
            }

            if (typeof event !== "string") {
              if (result2) {
                result2.math = event.target.value;
                result2.value = event.target.value;
                result2.chip = ''
                this.addChip(id, id2, event.target.value)
              }
            } else {
              if (result2) {
                result2.math = event;
                result2.value = event;
                result2.chip = ''
                this.addChip(id, id2, event)
              }
            }
          }

          if (type === 'input') {
            result2.input = !result2.input;
            ////console.log(event);
          }

          if (!result2) {
            treeElement.features = [];
            treeElement.features.push(content);
          }

        }


      }
      return null;
    },

    // Odczytywanie wartości z drzewa
    checkItem(id, id2 = null) {
      if (id !== null) {
        let treeElement = this.searchProductById(this.productsTree, id);

        ////console.log('treee', treeElement);
        if (treeElement !== null) {
          this.getCategoryFeatures(treeElement.categories, id)
          if (id2 !== null) {


            let feat = this.categoryFeatures[id].find((f) => f.id === id2);

            if (treeElement.features === undefined) {
              treeElement.features = [];
              treeElement.features.push({id: id2, unit: null, value: null, label: feat.title, input: false});
            } else {
              const rFeatures = treeElement.features.find((f) => f.id === id2);
              if (!rFeatures) {
                treeElement.features.push({id: id2, unit: null, value: null, label: feat.title, input: false});
              }
            }

            let row = treeElement.features.findIndex(e => e.id === id2);
            treeElement.features[row].chip = '';
            return treeElement.features[row];
          } else {
            return treeElement;
          }
        }


      }
      return null;
    },

    async remove(id, id2, chip) {

      let chipsCopy = JSON.parse(JSON.stringify(this.chips));
      let chipsMathCopy = JSON.parse(JSON.stringify(this.chipsMath));

      chipsCopy[id][id2].splice(chip, 1);
      this.chips = chipsCopy;

      chipsMathCopy[id][id2].splice(chip, 1);
      this.chipsMath = chipsMathCopy;

      let arrayMath = this.chipsMath;
      let math = '';
      arrayMath[id][id2].forEach((m) => {
        math = math + m;
      });

      await this.updateTree(math, id, 'math', id2);
      await this.getMath();
      await this.getMath();
      await this.getMath();
      await this.getMath();

    },

    // Aktualizacja math
    addChip(a, b, c, task = null) {
      if (c !== ' ' && c !== '') {
        let array = this.chips;
        if (!Array.isArray(array[a])) {
          array[a] = [];
        }
        if (!Array.isArray(array[a][b])) {
          array[a][b] = [];
        }

        array[a][b].push(c);
        this.chips = array;

        let arrayMath = this.chipsMath;

        if (!Array.isArray(arrayMath[a])) {
          arrayMath[a] = [];
        }
        if (!Array.isArray(arrayMath[a][b])) {
          arrayMath[a][b] = [];
        }
        if (task !== null) {
          arrayMath[a][b].push(task);
        } else {
          arrayMath[a][b].push(c);
        }
        this.chipsMath = arrayMath;

        let math = addMath.call(this, arrayMath, a, b);


        ////console.log("Dodajemy do math wartość", c.replace(',', '.'));

        let row = this.math.findIndex(e => e.idTree === a && e.feature === b);
        if (row !== -1) {
          this.math[row] = {
            idTree: Number(a),
            feature: Number(b),
            math: math,
            value: parseFloat(c.toString().replace(',', '.')),
            type: 'build'
          }
        } else {
          this.math.push(
              {
                idTree: Number(a),
                //product: Number(a),
                feature: Number(b),
                math: math,
                type: 'build'
              }
          );
        }
        this.clear = '';
        this.getMath();
      }
    },

    // Aktualizacja tablicy matematycznej o wpisywany wartości przez sprzedawcę
    updateMyProp($event, index) {
      const row = this.math.findIndex((el) => el.idTree === Number(index[2]) && el.product === Number(index[0]) && el.feature === Number(index[1]));
      if (row !== -1) {
        this.math[row] = {
          idTree: Number(index[2]),
          product: Number(index[0]),
          feature: Number(index[1]),
          value: Number($event)
        }
      } else {
        this.math.push(
            {
              idTree: Number(index[2]),
              product: Number(index[0]),
              feature: Number(index[1]),
              value: Number($event)
            }
        );
      }
    },

    changeExpanded(nodeId, expanded) {
      let items = this.productsTree.children;
      let nodes = [];
      for (let i = 0; i < items.length; i++) {
        nodes.unshift(items[i]);
      }
      let found = false;
      while (!found || nodes.length === 0) {
        let node = nodes.shift();

        if (node !== undefined) {
          if (node.id === nodeId) {
            found = true;
            node.expand = expanded;
          }
        }
        if (node.children !== undefined && node.children.length > 0) {
          node.children.forEach((item) => {
            nodes.unshift(item)
          });
        }
      }
    },
    changeValue(nodeId, newValue, feature) {
        let items = this.productsTree.children;
          let nodes = [];
          for (let i = 0; i < items.length; i++) {
            nodes.unshift(items[i]);
          }
          let found = false;
          while (!found || nodes.length === 0) {
            let node = nodes.shift();
            if (node !== undefined) {
              if (node.id === nodeId) {
                found = true;
                let _feature = node.features.find(({id}) => id === Number(feature));
                node.features[node.features.indexOf(_feature)].value = newValue;
              }
            }
            if (node.children !== undefined && node.children.length > 0) {
              node.children.forEach((item) => {
                nodes.unshift(item)
              });
            }
          }
    },
    flatTree(nodes, arr) {
      if (!nodes) {
        return [];
      }
      if (!arr) {
        arr = [];
      }
      for (var i = 0; i < nodes.length; i++) {
        arr.push(nodes[i]);
        if (nodes[i] !== null) {
          this.flatTree(nodes[i].children, arr);
        }
      }
      return arr;
    },

    async readValuesFromTree() {

      let flattenArray = await this.flatTree([this.productsTree], []);
      if (flattenArray !== null) {
        flattenArray.filter((value) => {

          ////console.log(value);

          if (value !== null && value.id !== 0) {
            if (value.features !== undefined && value.features.length > 0) {
              value.features.filter((feature) => {

                this.dialogCalc[value.id] = [];
                this.dialogCalc[value.id][feature.id] = false;

                let tree = {};
                ////console.log(Number(value.id), Number(value.product), Number(feature.id));
                //const row = this.math.findIndex((el) => el.idTree === Number(value.id) && el.product === Number(value.product) && el.feature === Number(feature.id));
                const row = this.math.findIndex((el) => el.idTree === Number(value.id) && el.feature === Number(feature.id));

                tree.idTree = value.id;
                tree.product = value.product;

                if (value.price !== null) {
                  tree.priceReady = value.price
                }
                if (value.price !== null) {
                  tree.priceUnit = value.priceUnit
                }
                tree.feature = feature.id;


                ////console.log(feature.value, feature.allowValue === false || feature.allowValue === undefined);

                if (feature.allowValue === false || feature.allowValue === undefined) {
                  tree.value = feature.value;
                } else {
                  if (row < 0) {
                    tree.value = 0;
                  } else {
                    tree.value = this.math[row].value;
                  }
                }

                tree.price = feature.price;
                tree.oneChildrenV = value.oneChildrenV;
                tree.math = feature.math;
                tree.mathTask = feature.mathTask;
                tree.type = 'build';

                ////console.log(value.children);

                if (value.children !== undefined) {
                  let selectedChildren_ = [];
                  value.children.filter((childrenProduct) => {
                    selectedChildren_.push(childrenProduct.id);
                  });
                  tree.children = selectedChildren_;
                }

                if (row < 0) {
                  this.math.push(tree);
                } else {
                  this.math[row] = tree;
                }
              })


            } else {

              //Dodajemy wartości gotowych produktów
              let tree = {};
              const row = this.math.findIndex((el) => el.idTree === Number(value.id) && el.feature === '??');

              ////console.log(row, value);

              tree.idTree = value.id;
              tree.product = value.product;
              tree.feature = '??';
              tree.type = value.type;
              //tree.product = value.product;
              if (value.price !== null) {
                tree.priceReady = value.price
              }
              if (value.price !== null) {
                tree.priceUnit = value.priceUnit
              }
              tree.feature = '??';

              if (value.children !== undefined) {
                let selectedChildren_ = [];
                value.children.filter((childrenProduct) => {
                  selectedChildren_.push(childrenProduct.id);
                });
                tree.children = selectedChildren_;
              }


              if (row < 0) {
                this.math.push(tree);
              } else {
                this.math[row] = tree;
              }

              ////console.log(this.math);

            }
          }
        });
      }

    },
    async getMath() {
      //for (let i = 0; i < 9; i++) {
      this.readValuesFromTree();
      this.getCalculations();
      //}

    },
    getCalculations() {
      this.math.filter((item, index) => {
            if (item.type === 'build' && item.math !== undefined) {

              let mathElements;
              /*              const mathElements_ = item.math.split(' ');
                            if (typeof mathElements_ === 'string') {
                              mathElements = mathElements_.trim();
                            } else {
                              mathElements = mathElements_;
                            }*/

              //console.log('mathElements Before', item.math);

              mathElements = rozdzielDzialanie(item.math);
              let _math = '';

              //console.log('mathElements', mathElements);

              mathElements.filter((mathElement) => {

                    //console.log('mathElement', mathElement);

                    if (mathElement) {


                      let pattern = /^[0-9]+([.,][0-9]+)?$/;
                      if (mathElement === "*" || mathElement === "/" || mathElement === "+" || mathElement === "-" || mathElement === "(" || mathElement === ")"
                          || pattern.test(mathElement)) {
                        _math += mathElement;
                      } else {

                        // Rozbijamy działanie matematyczne na części
                        // Wyciągamy identyfikatory

                        //console.log('mathElement split', mathElement);

                        const mathId = mathElement.split('_');

                        let mathProduct = mathId[0];
                        let mathFeature = mathId[1];
                        let mathContent = mathId[2];

                        if(mathProduct && mathFeature && !mathContent){
                          let variables = this.getVariableById(mathFeature);
                          _math += String(variables.value.replace(',','.'));
                        }

                        if (mathProduct && mathFeature && mathContent) {
                          let _selectChildren = null;

                          if (item.children !== undefined) {
                            item.children.filter((childrenProduct) => {
                              // Zaznaczony produkt RADIO
                              if (this.selected) {
                                let selectChildren = this.selected.filter(element => Number(element) === Number(childrenProduct));
                                if (selectChildren.length > 0) {
                                  _selectChildren = selectChildren[0];
                                }
                              }
                            })
                          }

                          if (mathProduct === "??" && mathFeature === "??") {
                            const row = this.math.find((el) => String(el.idTree) === String(_selectChildren));
                            if (row !== undefined) mathProduct = row;
                            if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
                          } else if (mathProduct === "??" && mathFeature !== "??") {

                            const row = this.math.find((el) => String(el.idTree) === String(_selectChildren)
                                && String(el.feature) === String(mathFeature));
                            if (row !== undefined) mathProduct = row;

                            if (row !== undefined && mathContent === "C" && mathProduct !== '??') {
                              _math += String(row.price);
                            }
                            if (row !== undefined && mathContent === "W" && mathProduct !== '??') {
                              _math += String(row.value);
                            }

                          } else if (mathProduct !== "??" && mathProduct !== "00" && mathFeature !== "??" && mathFeature !== "00") {

                            const row = this.math.find((el2) => {

                              if (String(el2.idTree) === String(mathProduct) &&
                                  String(el2.feature) === String(mathFeature)
                              ) {

                                if (item.children !== undefined) {
                                  if (item.children.find((el3) => el3 === el2.idTree)) {
                                    return el2
                                  }
                                }

                                if (String(el2.idTree) === String(item.idTree)) {
                                  return el2
                                }
                              }
                            });

                            if (row !== undefined && mathContent === "W" && mathProduct !== '??') {
                              if (row.value !== null) {
                                let price = row.value;
                                if (price) {
                                  _math += parseFloat(price.toString().replace(',', '.'));
                                }
                              }
                            }

                          } else if (mathProduct !== "??" && mathFeature === "??") {

                            let row = this.math.find((el) => String(el.idTree) === String(mathProduct));

                            if (row.type !== undefined && row.type === 'ready' && !row.priceReady) {

                              let index = this.math.findIndex(obj => obj.idTree === row.idTree);

                              if (index !== -1) {
                                this.math.splice(index, 1);
                              }

                              row = this.math.find((el) => String(el.idTree) === String(mathProduct));
                            }

                            if (row !== undefined) mathProduct = row;

                            if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
                            if (mathContent === "J" && mathProduct !== '??') _math += String(mathProduct.priceUnit);

                          } else if (mathProduct === "00" && mathFeature === "00") {

                            if (item.children !== undefined) {
                              item.children.filter((childrenProduct) => {
                                const row = this.math.find((el) => String(el.idTree) === String(childrenProduct));
                                if (row.priceReady !== undefined) {
                                  if (mathContent === "C") _math += String(row.priceReady);
                                  if (mathContent === "J") _math += String(row.priceUnit);
                                  _math += '+';
                                }
                              });

                              var lastChar = _math.charAt(_math.length - 1);
                              if (lastChar === "+") {
                                _math = _math.slice(0, -1);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
              )


              if (_math === "") {
                _math = '---';
              }


             //console.log('_math', _math);

              //this.changeValue(item.idTree, '', item.feature)

              try {
                let resultMath = eval(_math);
                resultMath = parseFloat(resultMath.toFixed(2));
                this.math[index].value = resultMath;

                this.changeValue(item.idTree, resultMath, item.feature)


              } catch (error) {
                ////console.log(error);
                //this.error[item.idTree] = [];
                //this.error[item.idTree][item.feature] = true;
                //this.changeValue(item.idTree, null, item.feature)
              }

            }

          }
      )
      ;
    },

    searchProductParent(element, product, elements) {
      if (element.product === product) {
        elements.push(element);
      }
      if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          this.searchProductParent(element.children[i], product, elements);
        }
      }
      return elements;
    }
    ,

    searchProduct(element, product, childrenArrayTree) {

      let _result = false;
      if (childrenArrayTree !== undefined && childrenArrayTree.length > 0) {
        if (childrenArrayTree.find(el => el === element.id)) {
          _result = true;
        }
      } else {
        _result = true;
      }


      if (element.product == product && _result) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchProduct(element.children[i], product, childrenArrayTree);
        }
        return result;
      }
      return null;
    }
    ,

    searchProductById(element, element_id) {
      if (Number(element.id) === Number(element_id)) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchProductById(element.children[i], element_id);
        }
        return result;
      }
      return null;
    },


    // Pobieranie drzewa - jeżeli niema tworzy puste
    async getListTree() {
      this.typeProject = 'project';
      if (this.$route.params.id !== "") {
        this.item = this.$route.params.id;
        this.itemId = this.$route.params.id;

        let data = await this.getProjectById(this.$route.params.id);
        if(data === undefined){
          data = await this.getProjectElementsById(this.$route.params.id);
          this.typeProject = 'element';
        }

        if(data){
          this.titleProject = data.title ?? '';

          this.productsTree = JSON.parse(data.tree);
          this.selected = data.selected;
          this.math = JSON.parse(data.math);
          this.chips = data.chips ? JSON.parse(data.chips) : [];
          this.chipsMath = data.chipsMath ? JSON.parse(data.chipsMath) : [];
          if (this.productsTree !== null) {
            this.getMath()
          }
          this.getMath()
        }


      } else {
        this.productsTree =
            {
              id: 0,
              label: "Wycena",
              children: [],
              expand: true,
              isPublic: true,
              type: null
            }
      }
    },

    checkParent(data) {
      let product = this.getParentId(this.productsTree.children, data, -1);
      return product;
    },

    // Pobieramy cechy kategorii
     getCategoryFeatures(idCategory, idTree) {
      //////console.log('getCategoryFeatures', idCategory, idTree)
      if (idCategory !== undefined && idCategory !== "") {
        let category =  this.getCategoryById(idCategory);
        if(category){
          if(category.features !==  undefined){
            this.categoryFeatures[idTree] = category.features;
          }
        }

      }
    },

    getParentId(array, id, parentId = null) {
      // For every entry in the array
      for (const entry of array) {
        // If the ID matches, return the current parent ID
        if (entry.id === id) {
          return parentId;
        }
        let deeperParentId = null;
        // Otherwise, call the same function on its children, passing itself as the parent.
        // If there was a match, return it.
        if (entry.children && (deeperParentId = this.getParentId(entry.children, id, entry.id))) {
          return deeperParentId;
        }
      }
      // No match was found
      return null;
    }
    ,
    toggleSelect(node, isSelected) {

      let radios = document.getElementsByName('radio_' + this.checkParent(node.id));

      ////console.log('radio_' + node.id, radios, radios.length);

      if (radios.length > 0) {
        for (var i = 0, length = radios.length; i < length; i++) {
          let idRadio = radios[i].getAttribute("value");

          ////console.log(radios[i].checked);

          if (radios[i].checked) {
            ////console.log('Dodajemy', Number(idRadio))
            this.selected.push(Number(idRadio))

            this.changeExpanded(Number(idRadio), true)


          } else {

            ////console.log(this.selected.indexOf(Number(idRadio)) != "-1")

            if (this.selected.indexOf(Number(idRadio)) != "-1") {
              this.selected.splice(this.selected.indexOf(Number(idRadio)), 1);

              ////console.log(Number(idRadio));
              this.changeExpanded(Number(idRadio), false)

              //this.math.find((element) => element.idTree == this.selected.indexOf(Number(idRadio)))
            }
            ////console.log('Usuwamy', Number(idRadio), this.selected.indexOf(Number(idRadio)))


          }
        }
      } else {

        let idRadio;
        ////console.log(removeItem)
        this.changeExpanded(Number(idRadio), false)

        /*if(removeItem !== undefined){
          this.math.splice(removeItem);
        }*/

        isSelected ? this.selected.push(node.id) : this.selected.splice(this.selected.indexOf(node.id), 1);
      }

      //this.getMath();

      /* ZAZNACZANIE WSZYSTKI DZIECI */
      /*if (node.children && node.children.length) {
        node.children.forEach(ch => {
          this.toggleSelect(ch, isSelected)
        })
      }*/
    }
    ,
    async saveProject(type, id = null) {

      let idProject = null;
      if (this.$route.params.id !== "" && type !== 'element') {
        idProject = this.$route.params.id;
      }

      if (type === 'project') {
        if (this.typeProject === 'element') {
          type = 'element';
        }
      }

      let tree = {};
      let title = '';


      tree = this.productsTree
      title = this.titleProject;

      if (this.titleProjectTree[id] !== undefined) {
        tree =
            {
              id: 0,
              label: "Wycena",
              children: [this.searchProductById(this.productsTree, id)],
              expand: true,
              isPublic: true,
              type: null
            };
        title = this.titleProjectTree[id];
      }


      const dataToUpdate = {
        title: title,
        math: JSON.stringify(this.math),
        tree: JSON.stringify(tree),
        selected: JSON.stringify(this.selected),
        chips: JSON.stringify(this.chips),
        chipsMath: JSON.stringify(this.chipsMath),
        type: type,
      }

      if(this.typeProject === 'project'){
        if(idProject){
          await this.$store.dispatch('updateProject', {item: dataToUpdate, idProject: idProject});
        }else{
          await this.$store.dispatch('addProject', dataToUpdate);
        }
      }else{
        if(idProject){
          await this.$store.dispatch('updateProjectElement', {item: dataToUpdate, idProject: idProject});
        }else{
          await this.$store.dispatch('addProjectElement', dataToUpdate);
        }
      }




      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      this.dialogTree[id] = false;
      this.dialog = false;

    },

    checkRole(e) {
      let _return = false;
      if (this.getRoles !== undefined) {
        e.forEach((o) => {
          _return = (this.getRoles.find((q) => q === o) !== undefined);
        })
        return _return;
      }
      return _return;
    },

    recursiveSearch(element) {
      if (element.id > this.highestID) {
        this.highestID = element.id;
      }

      if (Array.isArray(element.children)) {
        element.children.forEach((child) => {
          this.recursiveSearch(child);
        });
      }
    },
    findHighestID() {
      this.highestID = 0;

      this.recursiveSearch(this.productsTree);
    },

    tryAddLeaf(parentId, tree) {

      let isParent = tree.id == parentId;
      if (isParent) {
        // RANDOM ID
        //let some_id = parseInt(Math.random() * 100)

        this.findHighestID();
        let some_id = this.highestID + 1;
        // GET LAST ID

        let leaf = {
          label: ``,
          id: some_id,
        }
        if (!tree.children) {
          tree.expand = true;
          tree.children = [];
        }

        tree.children.push(leaf);
        if (some_id !== null) this.itemSelect.push({
          id: some_id,
          type: null,
        });

        let element = this.searchProductById(this.productsTree, some_id);
        element.type = null;


      } else if (tree.children) {
        tree.children.forEach(ch => this.tryAddLeaf(parentId, ch))
      }
    },
    deleteNode(node, tree) {

      let parent = tree.children ? tree.children.find(p => p.id == node.id) : null;
      if (parent) {
        tree.children.splice(tree.children.indexOf(node), 1)
      } else if (tree.children) {
        tree.children.forEach(ch => this.deleteNode(node, ch))
      }
    },

    async updateProducts(all = false) {

      let url = null;
      if (all === true) {
        url = 'https://api.gh.programigo.com/api/projects/updateAllProducts';
      } else {
        if (this.$route.params.id !== "") {
          url = 'https://api.gh.programigo.com/api/projects/updateProducts/' + this.$route.params.id;
        }
      }

      if (url != null) {
        await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        }).then(response => {
          let products = response.data;
          products.filter(p => {

            // update price in math
            let row = this.math.findIndex(e => e.product === p.id);
            if (row !== -1) {
              this.math[row]['priceReady'] = Number(p.priceReady);
              this.math[row]['priceUnit'] = Number(p.priceUnit);
            }

            // update tree
            const product = this.searchProduct(this.productsTree, p.id);
            if (product) {

              ////console.log(product, p)

              product.price = Number(p.priceReady);
              product.priceUnit = Number(p.priceUnit);
              product.features = p.features;
              product.priceLabelUnit = p.priceLabelUnit;
              product.priceReadyLabelUnit = p.priceReadyLabelUnit;
            }

            // update features
            if (p.features) {
              Object.entries(p.features).forEach(([key, value]) => {

                let row = this.math.findIndex(e => e.product === p.id && e.feature === Number(key));
                if (row !== -1) {
                  this.math[row]['value'] = value;
                }
              });
            }
          });

          this.$notify({
            title: "Produkty zostały zaktualizowane",
            type: 'success',
          });
        });
      }

    },

    async getById(id) {
      let data = null;
      await axios.get('https://api.gh.programigo.com/api/products/' + id, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      }).then(response => {
        data = response.data
      }).catch(error => {
        data = error;
      });
      return data;
    }

    ,
    menuClick(action) {
      alert("menuClick() " + action)
    },
    someFunc(action) {
      alert("someFunc() " + action)
    },


    findAndChangeDuplicates() {
      if(this.loadTreeData){
        const tree1Ids = this.getAllIds(this.productsTree); // Pobranie wszystkich identyfikatorów z drzewa pierwszego

        //console.log('Pobranie wszystkich identyfikatorów z drzewa pierwszego',tree1Ids)

        this.changeTree = JSON.parse(this.loadTreeData.tree);

        this.changeDuplicates(this.changeTree, tree1Ids); // Zmiana duplikatów w drzewie drugim
        //this.$notify({type: 'success',  title: "Element został zaaktualizowany"});
      }else{
        this.$notify({type: 'error', title: "Nie można zaaktualizować. Brak elementu źródłowego"});
      }

    },
    getAllIds(node) {
      // Rekurencyjna funkcja do pobrania wszystkich identyfikatorów z drzewa
      let ids = [];
      if (node.id) {
        ids.push(node.id);
      }
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          ids = ids.concat(this.getAllIds(childNode));
        }
      }
      return ids;
    },
    changeDuplicates(node, idsToCheck) {

      // Rekurencyjna funkcja do zmiany duplikatów identyfikatorów w drzewie

      //console.log('FindID', idsToCheck, node.id, idsToCheck.includes(node.id))

      if (idsToCheck.includes(node.id)) {

        let old = node.id;


        // Sprawdzenie, czy bieżący węzeł ma duplikat identyfikatora
        node.id = generateUniqueId(idsToCheck); // Wygenerowanie unikalnego identyfikatora
        this.duplicated[old] = node.id;
      }
      if (node.children && node.children.length > 0) {
        for (const childNode of node.children) {
          this.changeDuplicates(childNode, idsToCheck);
        }
      }
    },


    // Aktualizacja elementu drzewa
    async updateElement(treeId, elementId) {
      await this.loadTree(elementId);
      await this.loadTreeDown(this.checkParent(treeId),treeId);
    },

    async loadTree(event) {
      this.loadTreeData = await this.getProjectElementsById(event);
      await this.findAndChangeDuplicates();
    },

    loadTreeDown(id, idTree = null) {
      if(this.loadTreeData){
        let tree = this.searchProductById(this.productsTree, id);
        let tree2 = this.changeTree;

        //console.log('loadTreeDownChildren', tree2, tree);

        if(tree2.children.length > 0){
          let childrenTree = tree2.children;
          childrenTree[0]['element'] = this.loadTreeData.id;
          if(tree !== null){

            if(idTree !== null){
              const idChidren = tree.children.findIndex(children => children.id == idTree);
              tree.children[idChidren] = childrenTree[0];
            }else{
              if(tree.children === undefined){
                tree.children = childrenTree;
              }else{
                tree.children.push(childrenTree[0]);
              }
            }


          }
        }

        let chips = JSON.parse(this.loadTreeData.chips);
        let chipsMath = JSON.parse(this.loadTreeData.chipsMath);

        this.duplicated.forEach((newKey, index) => {
          chips[newKey] = chips[index];
          delete chips[index];
        })

        this.duplicated.forEach((newKey, index) => {
          chipsMath[newKey] = chipsMath[index];
          delete chipsMath[index];
        })

        this.chips = Object.assign(this.chips,chips);
        this.chipsMath = Object.assign(this.chipsMath,chipsMath);
      }
    },


// Rekurencyjna funkcja dodająca zmienną "color" do wszystkich gałęzi poniżej wybranej
    addColorToChildren(node, color) {
      if (node.children) {
        for (const child of node.children) {
          child.color = color;
          this.addColorToChildren(child, color);
        }
      }
    },
    addColorTextToChildren(node, color) {
      if (node.children) {
        for (const child of node.children) {
          child.colorText = color;
          this.addColorTextToChildren(child, color);
        }
      }
    },

    // Wgrywanie plików
    uploadFile(event) {

      event.preventDefault();

      const idTree = this.$refs.idTree.value;
      const file = this.$refs.fileInput.files[0];
      const title = this.$refs.productTitle.value;
      const productId = this.$refs.productId.value;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('productId', productId);
      formData.append('title', title);

      this.dialog = false;


      this.$notify({
        title: "Plik został przesłany. Trwa aktualizacja",
        type: 'success',
      });

      // Wykonaj żądanie API przy użyciu preferowanej metody (np. Axios)
      axios.post('https://api.gh.programigo.com/api/c/products/importFile/', formData)
          .then(response => {
            // Obsłuż odpowiedź API
            let elementTree = this.searchProductById(this.productsTree, idTree);
            elementTree.product = response.data.product_id;
            elementTree.image = response.data.images[0];
            elementTree.images = response.data.images;
            elementTree.files = response.data.files;

            this.$notify({
              title: "Aktualizacja przebiegła pomyślnie",
              type: 'success',
            });
          })
          .catch(error => {
            // Obsłuż ewentualne błędy
            console.error(error);
            this.$notify({
              title: "Wystąpił problem podczas aktualizacji produktów",
              type: 'error',
            });
          });
    }
  }
}


</script>

<style src="./style/style.css" lang="css">
</style>
