<template>

    <div v-for="(step, index) in tree" :key="index" class="float-left">
      <v-card
          variant="tonal"
          theme="dark"
          style="width: fit-content !important; "
          class="mr-2 card-pricing"

      >
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title @click="toggleStep(step)">
              {{ step.label }}
            </v-card-title>
            <v-card-subtitle @click="toggleStep(step)">
              <span v-if="step.price">Cena: {{ step.price }}</span>
              <span v-if="step.priceUnit">Cena jednostki : {{ step.priceUnit }}</span>
            </v-card-subtitle>
            <v-card-item class="pa-0" v-if="step.type === 'build'" @click.stop>
              <v-list>
                <template v-for="item in step.features"
                          :key="item.id">
                <v-list-item v-if="item.value || item.math || item.input === true">
                    <v-list-item-title @click="toggleStep(step)">
                      {{ item.label }}
                    </v-list-item-title>
<!--                                      <v-list-item-subtitle @click="toggleStep(step)">
                                        {{ step.id }}-{{item.id}} | {{item.math}}
                                      </v-list-item-subtitle>-->
                    <v-text-field  variant="outlined" density="compact" v-if="item.input === true" @input="updateMyProp($event.target.value, [step.id, item.id])" :disabled="!step.checked"></v-text-field>
                    {{item.value}}
                </v-list-item>
                </template>
              </v-list>
            </v-card-item>
            <v-card-actions @click="toggleStep(step)" style="min-height:30px;">
              <label>
                <input type="checkbox" v-model="step.checked">
              </label>
            </v-card-actions>
          </div>
        </div>
      </v-card>


      <div v-if="step.checked && step.children && step.children.length > 0">
        <br>
        <dynamic-form :tree="step.children" @selected="handleSelected" @updateMyProp="updateMyProp" />
      </div>
    </div>

  <div class="card"></div>

</template>

<script>
export default {
  name: 'DynamicForm',
  props: {
    tree: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedProducts: [],
    };
  },
  methods: {

    handleSelected(selectedStep) {
     //console.log('Zwracam',selectedStep)
      this.$emit('selected', selectedStep);
    },
    toggleStep(step) {

      if (step.checked === undefined || step.checked === false) {
       //console.log('Dodaje',step);
        this.selectedProducts.push(step);
        step.checked = true;
      } else {

       //console.log('Usuwam',step);

        const index = this.selectedProducts.findIndex((product) => product.id === step.id);
        if (index !== -1) {
          this.selectedProducts.splice(index, 1);
        }
        step.checked = false;
      }

      this.$emit('selected',step);

    },
    updateMyProp(event, array){



      if(array === undefined){
       //console.log(event[0],event[1]);
        this.$emit('updateMyProp',[event[0],event[1]]);
      }else{
       //console.log(event,array);
        this.$emit('updateMyProp',[event,array]);
      }


    }
  },
};
</script>
<style>
.card-pricing *{
  font-size: 10pt !important;
}
.card-pricing .v-card .v-card-title {
  line-height: 0.5rem;
}
</style>
