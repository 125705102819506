import axios from "axios";
export const projects = {
    state: {
        projects: []
    },
    getters: {
        getProjects: state => {
            return state.projects;
        },
        getProjectById: (state) => (id) => {
            let project = state.projects.find(item => item.id === Number(id));
            //console.log('getProjectById', project)
            return project;
        }
    },
    mutations: {
        setProjects(state, items) {
            state.projects = items;
        },
        removeProject(state, itemId) {
            state.projects = state.projects.filter(item => item.id !== itemId);
        },

        addProject(state, items) {
            state.projects.push(items);
        },
        addProjectElement(state, items) {
            state.projectsElements.push(items);
        },
        updateProject(state, data) {
            const index = state.projects.findIndex(item => item.id === data.idProject.toString());
            data.item.id = data.idProject.toString();
            state.projects.splice(index, 1, data.item);
        },
    },
    actions: {
        async loadProjects({commit, rootState }) {
            try {
                const response = await axios.get('https://api.gh.programigo.com/api/projects',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setProjects', items);
            } catch (error) {
                console.error('Error loading items:', error);
            }
        },
        async removeProject({commit, rootState }, itemId) {
            try {
                // Perform the asynchronous task to remove an item
                await axios.delete(`https://api.gh.programigo.com/api/projects/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}});

                // Commit the mutation to update the state
                commit('removeProject', itemId);
            } catch (error) {
                console.error('Error removing item:', error);
            }
        },
        async addProject({commit, rootState }, data) {

            try {
                await axios.post('https://api.gh.programigo.com/api/projects',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                            commit('addProject', items);
                            commit('setResponseMessage', 'Projekt został dodany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateProject({commit, rootState }, data) {
            try {
                await axios.put('https://api.gh.programigo.com/api/projects/' + data.idProject,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                            commit('updateProject', data);
                            commit('setResponseMessage', 'Projekt został zaktualizowany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        console.log(error);
                        commit('setResponseMessage', '0:' + error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', '1:' + error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
