<template>
  <!-- @change="change($event.target.value)" -->
  <v-row>
<!--    <v-col>
      <v-radio-group
          :modelValue="type"
          @change="$emit('update:type', $event.target.value)"
      >
        <template v-slot:label>
          <div>Wybierz rodzaj <strong>tego elementu</strong></div>
        </template>
        <v-radio v-for="item in types" :value="item.value" :key="item.id" :label="item.label" />
      </v-radio-group>
    </v-col>-->
    <v-col>
      <v-switch
          inset
          density="compact"
          v-model="switch_"
          @change="switchPublished()"
          label="Element widoczny dla sprzedawcy"
          color="success"></v-switch>
      <v-switch
          inset
          density="compact"
          v-model="switchChildren_"
          @change="switchChildren()"
          label="Można wybrać tylko jedno 'dziecko'"
          color="success"></v-switch>
    </v-col>
  </v-row>

</template>
<script>
export default {
  name: 'ProductType',
  data() {
    return {
      switch_: false,
      switchChildren_: false,
      selectType: 'category',
      types: [
        {
          label: 'Element drzewa produktów',
          value: 'category'
        },
        {
          label: 'Produkt tworzony',
          value: 'build'
        },
        {
          label: 'Produkt handlowy - materiał',
          value: 'ready',
        }
      ]
    }
  },
  props: {
    type: String,
    published: Boolean,
    children: Boolean,
  },
  emits: [
    'update:type',
    'update:published',
    'update:children',
  ],
  mounted() {
    this.getSwitch();
    this.getSwitchChildren();
  },
  methods: {
    switchPublished() {
      this.$emit('update:published', this.switch_)
    },
    switchChildren(){
      this.$emit('update:children', this.switchChildren_)
    },
    getSwitch(){

     //console.log(this.published)

      this.switch_ = this.published
    },
    getSwitchChildren(){
     //console.log(this.children)
      this.switchChildren_ = this.children
    }
  },

}
</script>
<style>
.v-input--switch__track {
  border-radius: 15px;
  width: 48px;
  height: 19px;
  top: -2px;
}
.v-switch--inset .v-switch__track{
  height: 18px;
}
.v-switch__thumb{
  width:10px;
  height:10px;
}
.v-input--switch__thumb {
  left: 6px;
}

.custom-switch .v-input__slot .v-label {
  left: 6px !important
}

.v-input--selection-controls__ripple {
  height: 0;
  width: 0
}
</style>
