<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
      <v-dialog
          v-model="dialog"
          width="600"
          height="500"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              color="blue"
              style="margin-top:6px;"
              class="float-end"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-plus"
          >
            Dodaj nową jednostkę
          </v-btn>

        </template>
        <v-card>
          <v-card-text>
            <v-form>
              <v-text-field
                  label="Jednostka miary"

                  density="compact"
                  v-model="title"
                  hide-details="auto"
                  class="mb-5"
                  counter
                  maxlength="20"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn color="blue" @click="fetchAddUnit(itemId)">Zapisz</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="secondary" @click="dialog = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-col>
  </v-row>


  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          single-line
          hide-details
          density="compact"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        density="compact"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="units.units"
        :loading="loading"
        :search="search"
        class="elevation-1"
        item-value="id"
        @update:options="getList"
    >

      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.selectable.id }}</td>
          <td>{{ item.selectable.title }}</td>
          <td align="end">
            <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                   @click="editItem(item.selectable)">
              Edytuj
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="red"
                   @click="fetchDeleteUnit(item.selectable.id)">
              Usuń
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>


</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

export default {
  name: 'UnitsView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      title: '',
      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: false,
          to: 'products',
        },
        {
          title: 'Kategorie',
          disabled: false,
          to: 'categories',
        },
        {
          title: 'Cechy',
          disabled: false,
          to: 'features',
        },
        {
          title: 'Jednostki miary',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: true,
      totalItems: 10,
      itemId: null,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken']),
    ...mapState(["units"])
  },
  created() {
    this.fetchLoadUnits();
  },
  methods: {
    ...mapActions(['loadUnits','addUnit']),
    async fetchLoadUnits() {
      await this.loadUnits();
      this.loading = false
    },
    async fetchAddUnit(idUnit) {
     //console.log('fetchAddUnit',idUnit);
      if(idUnit){
        await this.$store.dispatch('updateUnit', {item: {title: this.title}, idUnit: idUnit});
      }else{
        await this.$store.dispatch('addUnit', {title: this.title});
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if(this.$store.state.users.responseStatus === 'success'){
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteUnit(idUnit) {
      await this.$store.dispatch('removeUnit', idUnit);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(unit) {
      this.dialog = true
      this.title = unit.title
      this.itemId = unit.id
    },
  }
}
</script>
