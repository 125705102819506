<template>

  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>

      <v-btn
          color="red"
          style="margin-top:6px;"
          class="float-end mx-2"
          v-bind="props"
          size="x-small"
          prepend-icon="mdi-format-list-bulleted-square"
          to="/produkty"
      >
        Wszystkie produkty
      </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card title="Dane klienta" text="">
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Imie"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Nazwisko"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Ulica"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Nr domu"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Nr lokalu"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Kod"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Miasto"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Telefon"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  label="Email"
                  :rules="meanUnit"
                  v-model="title"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="product !== null">
      <v-card title="Dane zamówienia">
        <v-card-item>
          <strong>Data wprowadzenia:</strong>
          {{getFormattedDate(product.dateAdd.date)}}
        </v-card-item>
        <v-card-item>
          <strong>Data modyfikacji:</strong>
          {{getFormattedDate(product.dataModify.date)}}
        </v-card-item>
        <v-card-item>
          <strong>Data ważności:</strong> ---
        </v-card-item>
      </v-card>
      <v-row class="mt-3">
        <v-col>
          <v-btn prepend-icon="mdi-file-pdf-box" :href="'https://' + host + '/api/c/projects/getOfferPdf/'" target="_blank" width="100%" color="red" size="x-large" stacked variant="elevated">
            Oferta
          </v-btn>
        </v-col>
        <v-col><v-btn prepend-icon="mdi-file-pdf-box" :href="'https://' + host + '/api/c/projects/getOfferPdf/'" target="_blank" width="100%"  color="red" size="x-large" stacked variant="elevated">
          Oferta PRO
        </v-btn></v-col>
        <v-col>
          <v-btn prepend-icon="mdi-file-tree-outline" :to="'/products/tree2/' +  $route.params.id " width="100%"  color="blue" size="x-large" stacked variant="elevated">
            Drzewo
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

  </v-row>

</template>
<script>
import axios from "axios";
import moment from 'moment';
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {mapGetters} from "vuex";
export default {
  name: "ProjectsSingleView",
  components: {BreadcrumbsComponent},

  data() {
    return {
      breadcrumbs: [
        {
          title: 'Projekt',
          disabled: true,
        },
      ],
      product:null,
      host: 'api.gh.programigo.com',
    }
  },
  mounted: function () {
    this.getById()
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken'])
  },
  methods: {
    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD")
    },
    getById() {
      if (this.$route.params.id !== "") {
        this.item = this.$route.params.id;
        this.itemId = this.$route.params.id;

        axios.get('https://api.gh.programigo.com/api/c/projects/getById/' + this.$route.params.id,
            {
              headers: {
                Authorization: `Bearer ${this.getToken}`
              }
            })
            .then(response => {
              this.product = response.data;
            });
      } else {
        this.newData()
      }
    }
  }
}
</script>

<style>

</style>
