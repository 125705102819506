<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
    </v-col>
  </v-row>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          single-line
          hide-details
          density="compact"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        v-if="projects"
        density="compact"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="projects.projects"
        :search="search"
        class="elevation-1"
        item-value="id"
    >

      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.selectable.id }}</td>
          <td>{{ item.selectable.title }}</td>
          <td align="end">
            <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                    :to="'/products/tree2/' +  item.selectable.id ">
              Drzewo
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="red"
                   @click="removeItem(item.selectable.id)">
              Usuń
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

export default {
  name: 'ProjectsView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Projekty',
          disabled: true,
        },
      ],
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      totalItems: 10,
      itemId: null
    };
  },
  computed: {
    ...mapState(["projects"]),
    ...mapGetters(["isLoggedIn", 'getToken', 'getRoles()'])
  },
  methods: {
    ...mapActions(['loadProjects', 'removeProject']),
    async fetchProjects() {
      await this.loadProjects();
    },
    async removeItem(itemId) {
      await this.removeProject(itemId);
    },
  },
  created() {
    this.fetchProjects();
  }
}
</script>
