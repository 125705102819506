<template>
  <v-card v-if="loading === false" height="100%">
    <v-card-text>
      <v-row>
        <v-col>

          <ProductDefaultForm
              :title="title" @update:title="title = $event"
              :description="description" @update:description="description = $event"
              :tags="tags" @update:tags="tags = $event"
          />

          <ProductType v-if="published !== null && oneChildren !== null"
                       :published="published" @update:published="published = $event"
                       :children="oneChildren" @update:children="oneChildren = $event"
                       :type="type" @update:type="type = $event"
          />
          <template v-if="id">
            <Uploader class="my-4"
                      :server="server + id"
                      :media="media.saved"
                      location="https://api.gh.programigo.com"
                      @add="addMedia"
                      @remove="removeMedia"
            />
          </template>


        </v-col>

        <v-col>
          <v-row>
            <v-col>
              <v-card variant="outlined" style="border-color: rgb(101,101,101);">
                <v-card-item>
                  <v-row v-if="type === 'ready'">
                    <v-col cols="6">
                      <v-select
                          class="mt-1"
                          v-model="calculate"
                          label="Przelicznik"
                          :items="[
                              {
                                id: 'null',
                                title: '---'
                              },
                              {
                                id: 'cj',
                                title: 'Cena > Jednostka'
                              },
                              {
                                id: 'jc',
                                title: 'Jednostka > Cena'
                              }
                          ]"
                          item-value="id"
                          variant="outlined"
                          density="compact"
                          hide-details
                          @input="calc"
                      ></v-select>

                    </v-col>
                    <v-col cols="6">
                      <v-select
                          v-if="categoryFeatures"
                          class="mt-1"
                          v-model="converter"
                          label="Przelicznik"
                          :items="categoryFeatures.features"
                          item-value="id"
                          variant="outlined"
                          density="compact"
                          hide-details
                          @input="calc"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="type === 'ready'">
                    <v-col>
                      <v-select
                          class="mt-3"
                          density="compact"
                          label="Waluta"
                          :items="['PLN', 'EUR']"
                          item-value="id"
                          hide-details="auto"
                          variant="outlined"
                          v-model="currency"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                          class="mt-3"
                          density="compact"
                          hide-details
                          variant="outlined"
                          v-model="price"
                          label="Cena całkowita"
                          :disabled="calculate === 'jc'"
                          @input="calc"
                      ></v-text-field>

                      <v-select

                          class="mt-3"
                          density="compact"
                          label="Jednostka"
                          :items="units.units"
                          item-value="id"
                          hide-details="auto"
                          variant="outlined"
                          v-model="unit"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                          class="mt-3"
                          density="compact"
                          hide-details
                          variant="outlined"
                          v-model="priceUnit"
                          label="Cena jednostki"
                          :disabled="calculate === 'cj'"
                          @input="calc"
                      ></v-text-field>
                      <v-select
                          class="mt-3"
                          density="compact"
                          label="Jednostka"
                          :items="units.units"
                          item-value="id"
                          hide-details="auto"
                          variant="outlined"
                          v-model="unit2"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5" v-if="type === 'ready'">
                    <v-col>
                      <v-text-field
                          class="mt-3"
                          density="compact"
                          hide-details
                          variant="outlined"
                          v-model="ean"
                          label="EAN"
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <div v-if="type === 'ready' || type === 'build'">
                    <v-select
                        v-if="categories.categories !== null"
                        density="compact"
                        label="Wybierz kategorię"
                        :items="categories.categories"
                        item-value="id"
                        variant="outlined"
                        v-model="selectedCategory"
                    ></v-select>
                    <div v-if="categoryFeatures !== undefined">
                      <div v-if="categoryFeatures.features!==undefined">
                        <v-row v-for="item in categoryFeatures.features"
                               :key="item.id">
                          <v-col cols="9">
                            <v-text-field
                                density="compact"
                                :label="item.title"
                                variant="outlined"
                                class="mb-3"
                                hide-details="auto"
                                v-model="featureValue[item.id]"
                                @input="calc"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                                v-if="item.units.length > 0"
                                density="compact"
                                label="Jednostka"
                                :items="item.units"
                                item-value="id"
                                hide-details="auto"
                                variant="outlined"
                                v-model="featureUnit[item.id]"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </v-card-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
          class="float-end mx-1"
          style="margin-top:6px;"
          color="indigo-darken-3"
          size="x-small"
          variant="flat"
          prepend-icon="mdi-content-save"
          @click="fetchAddProduct(itemId)">Zapisz
      </v-btn>
    </v-card-actions>
  </v-card>
  <div v-else-if="loading === true">
    <div class="text-center">
      <v-progress-circular
          indeterminate
          color="red"
          :size="70"
          :width="7"
      ></v-progress-circular>
    </div>
  </div>

</template>
<script>
import axios from "axios";
import Uploader from 'vue-media-upload';
import 'vue3-treeselect/dist/vue3-treeselect.css'
import 'floating-vue/dist/style.css'
//import {Mentionable} from "vue-mention";

import ProductType from "@/views/products/products/ProductType.vue";
import ProductDefaultForm from "@/views/products/products/ProductDefaultForm.vue";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "ProductsSingleDialogView",
  props: ['productId'],
  components: {
    //Mentionable,
    ProductType,
    ProductDefaultForm,
    Uploader,
  },


  data() {
    return {

      // Walidacja
      validTitle: [
        value => !!value || 'Wymagane.',
        value => (value && value.length >= 2) || 'Min 2 znaki',
        value => (value && value.length <= 240) || 'Max 240 znaków',
      ],
      featureMath: [],


      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: false,
          to: "/products"
        },
        {
          title: 'Szczegóły',
          disabled: true,
        },
      ],

      // Przełączanie widoków
      //readyPrice: false,
      //workPrice: false,

      // Elementy pobrane
      projectsList: null,
      categoriesList: null,

      // Wartości pól
      title: '',
      description: '',
      tags: '',
      published: null,
      oneChildren: null,
      type: 'ready',
      calculate: 'null',
      converter: null,
      currency: 'PLN',
      ean: null,
      price: null,
      priceUnit: null,
      unit: null,
      unit2: null,
      selectedCategory: [],
      featureValue: [],
      featureUnit: [],

      selectedProjects: [],
      selectedParents: [],


      meanUnitData: [],

      featurePrice: [],
      featureDefault: [],
      featureAllowValue: [],
      featureTypeValue: [],
      featureMin: [],
      featureMax: [],

      math: '',


      consumption: null,


      childrenCalc: [],
      currentCalc: [],

      // ####################

      categoryFeatures: null,


      // Pola formularzy


      items: [],

      isSelectionModeDisabled: true,
      isRecursiveDisabled: true,
      selectNodesRecursiveValue: false,
      selectByClickValue: true,
      selectionModeValue: 'multiple',

      feature: [],


      media: {
        saved: [],
        added: [],
        removed: []
      },

      product: null,

      searchMode: 'contains',
      searchValue: '',
      searchValue2: '',

      dialogProduct: false,
      features: {},
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: true,
      totalItems: 0,
      itemId: null,
      item: null,

      id: null,
      parametersParent: null,
      server: 'https://api.gh.programigo.com/api/c/products/addImages/',
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', 'getCategoryById', 'getReadyProductById']),
    ...mapState(["features", "units", 'categories', 'readyProducts', 'currentProduct']),
  },
  watch: {
    async selectedCategory() {
      this.categoryFeatures = await this.getCategoryById(this.selectedCategory);
      if (this.categoryFeatures) {
        this.categoryFeatures.features.forEach((feature) => {
          if (feature.units.length > 0) {
            this.featureUnit[feature.id] = feature.units[0].id
          }
        })
      }
    },
  },
  created() {
    this.fetchLoadCategories();
    this.fetchLoadFeatures();
    this.fetchLoadUnits();
    this.getById()
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.getParametersParent()
      }
    }
  },

  methods: {
    ...mapActions(['loadCategories', 'loadFeatures', 'loadUnits', 'addProduct', 'updateProduct']),
    async fetchLoadCategories() {
      await this.loadCategories();
    },
    async fetchLoadFeatures() {
      await this.loadFeatures();
    },
    async fetchLoadUnits() {
      await this.loadUnits();
    },

    async fetchAddProduct() {
      let productId = null;
      if (this.$props.productId) {
        productId = this.$props.productId;
      } else if (this.$store.state.readyProducts.currentProduct) {
        productId = this.$store.state.readyProducts.currentProduct;
      }

      const data = {
        title: this.title,
        description: this.description,
        tags: this.tags,
        public: this.published,
        oneChildren: this.oneChildren,
        type: this.type,
        calculate: this.calculate,
        converter: this.converter,
        currency: this.currency,
        ean: this.ean,
        price: this.price,
        priceUnit: this.priceUnit,
        unit: this.unit,
        unit2: this.unit2,
        selectedCategory: this.selectedCategory,
        featureValue: this.featureValue,
        featureUnit: this.featureUnit,
      }
      if (productId) {
        await this.$store.dispatch('updateProduct', {
          item: data, productId: productId
        });
      } else {
        await this.$store.dispatch('addProduct', data);
        await this.getById();
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
    },


    calc() {
      if (this.featureValue[this.converter] !== undefined) {
        if (this.calculate === 'cj') {
          this.priceUnit = parseFloat(this.price.toString().replace(',', '.')) * this.featureValue[this.converter].toString().replace(',', '.');
          this.priceUnit = parseFloat(this.priceUnit.toString().replace(',', '.'));
        } else if (this.calculate === 'jc') {
          this.price = parseFloat(this.priceUnit.toString().replace(',', '.')) * this.featureValue[this.converter];
          this.price = parseFloat(this.price.toString().replace(',', '.'));
        }
      }
    },

    // Czyszczenie formularza
    newData() {
      this.id = null
      this.title = ''
      this.description = ''
      this.tags = ''
      this.selectedCategory = ''
      this.meanUnitData = []
      this.features = {}
      this.categoryFeatures = []
      this.selectedFeatures = []
      this.selectedParents = []
      this.feature = []
      this.published = false
      this.oneChildren = false
    },


// Pobieramy szczegóły produktu
    async getById() {

      let productId = null;
      if (this.$props.productId) {
        productId = this.$props.productId;
      } else if (this.$store.state.readyProducts.currentProduct) {
        productId = this.$store.state.readyProducts.currentProduct;
      }


      if (productId !== "" && productId !== null) {
        this.product = await this.getReadyProductById(productId);
        //console.log(this.product);
        this.selectedCategory = this.product.groupId;
        this.categoryFeatures = await this.getCategoryById(this.selectedCategory);

        this.featureValue = [];
        this.featureUnit = [];
        if (this.product.features) {
          await this.product.features.forEach((feature) => {
            if (feature.features) {
              this.featureValue[feature.features.id] = feature.value;
              if (feature.meanUnits !== undefined) {
                this.featureUnit[feature.features.id] = feature.meanUnits.id;
              }
            }
          })
        }


        this.title = this.product.title;
        this.description = this.product.content
        this.tags = this.product.tags
        this.type = this.product.type
        this.price = this.product.price
        this.priceUnit = this.product.priceUnit
        this.unit = this.product.unit ? this.product.unit.id : null;
        this.unit2 = this.product.unit2 ? this.product.unit2.id : null;
        this.currency = this.product.currency
        this.calculate = this.product.calculate

        this.ean = this.product.ean
        this.math = this.product.math
        this.published = this.product.public
        this.oneChildren = this.product.oneChildren
        this.converter = this.product.converter

        if (this.categoryFeatures) {
          this.categoryFeatures.features.forEach((feature) => {
            if (this.featureUnit[feature.id] === undefined) {
              if (feature.units.length > 0) {
                this.featureUnit[feature.id] = feature.units[0].id
              }
            }
          })
        }

        this.loading = false;
      } else {
        this.newData()
        this.loading = false;
      }

    }
    ,


    addMedia(addedImage, addedMedia) {
      this.media.added = addedMedia
      //console.log(this.media);
      axios.post('https://api.gh.programigo.com/api/c/products/addImages/' + this.id, {
        addedImage: this.media.added
      }, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      }).then(response => {
        this.media.saved = response.data;
        //console.log(response);
        this.$notify({
          title: "Zmiany zostały zapisane",
          type: 'success',
        });
      }).catch(error => {
        this.$notify({
          title: "Zmiany nie zostały zapisane.",
          text: error,
          type: 'error',
        });
      })

    }
    ,
    removeMedia(removedImage, removedMedia) {
      this.media.removed = removedMedia
      axios.post('https://api.gh.programigo.com/api/c/products/removeImage/' + this.id,
          {removedImage: this.media.removed},
          {headers: {Authorization: `Bearer ${this.getToken}`}});
    }
    ,
  }
}
</script>

<style>

.vue-treeselect__control {
  padding-left: 5px;
  padding-right: 5px;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 36px;

  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: rgb(var(--v-theme-surface));
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-property: border-color, box-shadow, width, height, background-color, opacity;
  transition-property: border-color, box-shadow, width, height, background-color, opacity;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) .vue-treeselect__control:hover {
  background-color: rgb(var(--v-theme-surface));
}

.vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
  border-color: rgb(var(--v-theme-surface-variant));
  box-shadow: 0 0 0 3px rgba(3, 155, 229, .1)
}

.vue-treeselect--disabled .vue-treeselect__control {
  background-color: rgb(var(--v-theme-surface));
}

.vue-treeselect--open .vue-treeselect__control {
  border-color: #cfcfcf
}

.vue-treeselect__placeholder {
  color: #bdbdbd
}

.vue-treeselect__single-value {
  color: #333
}

.vue-treeselect--focused.vue-treeselect--searchable .vue-treeselect__single-value {
  color: #bdbdbd
}


.vue-treeselect:not(.vue-treeselect--disabled) .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-disabled):hover .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-new) .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-new):hover, .vue-treeselect__multi-value-item {
  cursor: pointer;
  background-color: rgb(var(--v-theme-surface));
  color: rgb(var(--v-theme-surface-variant));
}

.vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-disabled {
  cursor: default;
  background-color: rgb(var(--v-theme-surface));
  color: rgb(var(--v-theme-surface-variant));
}

.vue-treeselect--disabled .vue-treeselect__multi-value-item {
  cursor: default;
  background-color: rgb(var(--v-theme-surface));
  color: rgb(var(--v-theme-surface-variant));
}

.vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new, .vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new:hover {
  background-color: rgb(var(--v-theme-surface));
}

.vue-treeselect__value-remove {
  color: rgb(var(--v-theme-surface-variant));
  padding-left: 5px;
  border-left: 1px solid #fff;
  line-height: 0
}

[dir=rtl] .vue-treeselect__value-remove {
  border-left: 0 none;
  border-right: 1px solid #fff
}

.vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove {
  color: #e53935
}


.vue-treeselect__limit-tip-text {
  cursor: default;
  display: block;
  margin: 2px 0;
  padding: 1px 0;
  color: #bdbdbd;
  font-size: 12px;
  font-weight: 600
}

.vue-treeselect__x-container {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  text-align: center;
  line-height: 0;
  cursor: pointer;
  color: #ccc;
  -webkit-animation: vue-treeselect-animation-fade-in .2s cubic-bezier(.075, .82, .165, 1);
  animation: vue-treeselect-animation-fade-in .2s cubic-bezier(.075, .82, .165, 1)
}

.vue-treeselect__x-container:hover {
  color: #e53935
}


.vue-treeselect__control-arrow {
  width: 9px;
  height: 9px;
  color: #ccc
}

.vue-treeselect:not(.vue-treeselect--disabled) .vue-treeselect__control-arrow-container:hover .vue-treeselect__control-arrow {
  color: #616161
}


.vue-treeselect__menu {
  cursor: default;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  width: auto;
  border: 1px solid #cfcfcf;
  background-color: rgb(var(--v-theme-surface));
  line-height: 180%;
  -webkit-overflow-scrolling: touch;
  z-index: 99999;
}

.vue-treeselect--open-below .vue-treeselect__menu {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 0;
  margin-top: -1px;
  border-top-color: #f2f2f2;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .06)
}

.vue-treeselect--open-above .vue-treeselect__menu {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  margin-bottom: -1px;
  border-bottom-color: #f2f2f2
}


.vue-treeselect__option--highlight {
  background-color: rgb(var(--v-theme-surface));
}

.vue-treeselect--single .vue-treeselect__option--selected {
  background-color: rgb(var(--v-theme-surface));
  font-weight: 600
}

.vue-treeselect--single .vue-treeselect__option--selected:hover {
  background-color: rgb(var(--v-theme-surface));
}

.vue-treeselect__option-arrow {
  display: inline-block;
  width: 9px;
  height: 9px;
  color: #ccc;
  vertical-align: middle;
  -webkit-transition: -webkit-transform .2s cubic-bezier(.19, 1, .22, 1);
  transition: -webkit-transform .2s cubic-bezier(.19, 1, .22, 1);
  transition: transform .2s cubic-bezier(.19, 1, .22, 1);
  transition: transform .2s cubic-bezier(.19, 1, .22, 1), -webkit-transform .2s cubic-bezier(.19, 1, .22, 1);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}


.vue-treeselect__checkbox--unchecked {
  border-color: #e0e0e0;
  background-color: rgb(var(--v-theme-surface));
}

.vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
  border-color: rgb(var(--v-theme-surface-variant));
  background-color: rgb(var(--v-theme-surface));
}

.vue-treeselect__checkbox--checked, .vue-treeselect__checkbox--indeterminate, .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked, .vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate {
  border-color: rgb(var(--v-theme-surface-variant));
  background: #039be5
}

.vue-treeselect__checkbox--disabled, .vue-treeselect__label-container:hover .vue-treeselect__checkbox--disabled {
  border-color: #e0e0e0;
  background-color: rgb(var(--v-theme-surface));
}


.vue-treeselect__tip {
  padding-left: 5px;
  padding-right: 5px;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #757575
}

.vue-treeselect__tip-text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 12px
}

.vue-treeselect__error-tip .vue-treeselect__retry {
  cursor: pointer;
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  color: #039be5
}

[dir=rtl] .vue-treeselect__error-tip .vue-treeselect__retry {
  margin-left: 0;
  margin-right: 5px
}

.vue-treeselect__icon-container {
  display: table-cell;
  vertical-align: middle;
  width: 20px;
  text-align: center;
  line-height: 0
}

.vue-treeselect--single .vue-treeselect__icon-container {
  padding-left: 5px
}

[dir=rtl] .vue-treeselect--single .vue-treeselect__icon-container {
  padding-left: 0;
  padding-right: 5px
}

.vue-treeselect__icon-warning {
  display: block;
  margin: auto;
  border-radius: 50%;
  position: relative;
  width: 12px;
  height: 12px;
  background: #fb8c00
}

.vue-treeselect__icon-warning:after {
  display: block;
  position: absolute;
  content: "";
  left: 5px;
  top: 2.5px;
  width: 2px;
  height: 1px;
  border: 0 solid #fff;
  border-top-width: 5px;
  border-bottom-width: 1px
}

.vue-treeselect__icon-error {
  display: block;
  margin: auto;
  border-radius: 50%;
  position: relative;
  width: 12px;
  height: 12px;
  background: #e53935
}

.vue-treeselect__icon-error:after, .vue-treeselect__icon-error:before {
  display: block;
  position: absolute;
  content: "";
  background-color: rgb(var(--v-theme-surface));
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.vue-treeselect__icon-error:before {
  width: 6px;
  height: 2px;
  left: 3px;
  top: 5px
}

.vue-treeselect__icon-error:after {
  width: 2px;
  height: 6px;
  left: 5px;
  top: 3px
}

.vue-treeselect__icon-loader {
  display: block;
  margin: auto;
  position: relative;
  width: 12px;
  height: 12px;
  text-align: center;
  -webkit-animation: vue-treeselect-animation-rotate 1.6s linear infinite;
  animation: vue-treeselect-animation-rotate 1.6s linear infinite
}

.vue-treeselect__icon-loader:after, .vue-treeselect__icon-loader:before {
  border-radius: 50%;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: .6;
  -webkit-animation: vue-treeselect-animation-bounce 1.6s ease-in-out infinite;
  animation: vue-treeselect-animation-bounce 1.6s ease-in-out infinite
}

.vue-treeselect__icon-loader:before {
  background: #039be5
}

.vue-treeselect__icon-loader:after {
  background: #b3e5fc;
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s
}

.vue-treeselect__menu-placeholder {
  display: none
}

.vue-treeselect__portal-target {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: visible;
  box-sizing: border-box
}


/* -------------------- */

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

/*input.v-field__input, div.v-field__input {
  height: 30px;
  min-height: 30px;
  font-size: 10pt;
}*/

textarea.v-field__input {
  font-size: 10pt;
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
  background: rgb(68, 68, 68);
  font-size: 10pt;
}

.mention-selected {
  background: rgb(120, 122, 118);
}
</style>
