<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
      <v-dialog
          v-model="dialog"
          persistent
          width="1024"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              color="blue"
              style="margin-top:6px;"
              class="float-end"
              size="x-small"
              prepend-icon="mdi-plus"
              v-bind="props"
          >
            Dodaj użytkownika
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Dodawanie użytkownika</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
                prepend-icon="mdi-email"
                name="email"
                label="Email"
                type="text"
                v-model="email"
            ></v-text-field>
            <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue-darken-1"
                variant="text"
                @click="dialog = false"
            >
              Zamknij
            </v-btn>
            <v-btn
                color="blue-darken-1"
                variant="text"
                @click="fetchAddUser"
            >
              Zapisz
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-col>
  </v-row>


  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          single-line
          hide-details
          density="compact"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        density="compact"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="users.users"
        :loading="loading"
        :search="search"
        class="elevation-1"
        item-value="id"
    >

      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.selectable.id }}</td>
          <td>{{ item.selectable.email }}</td>
          <td align="end">

            <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                   :to="'/user/profile/' + item.selectable.id">
              Edytuj
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="red"
                   @click="fetchDeleteUser(item.columns.id)">
              Usuń
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>
</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

export default {
  name: 'UsersView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Użytkownicy',
          disabled: false,
          to: '/users',
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Email', key: 'email', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: true,
      totalItems: 10,
      itemId: null,
      email: "",
      password: "",
      passwordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
      ],

    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken']),
    ...mapState(["users"])
  },
  created() {
    this.fetchLoadUsers();
  },
  methods: {
    ...mapActions(['loadUsers','addUser']),
    async fetchLoadUsers() {
      await this.loadUsers();
      this.loading = false
    },
    async fetchAddUser() {
      await this.$store.dispatch('addUser', {email: this.email, password: this.password});
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if(this.$store.state.users.responseStatus === 'success'){
        this.dialog = false;
      }
    },
    async fetchDeleteUser(idUser) {
      await this.$store.dispatch('removeUser', idUser);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
  }
}
</script>
