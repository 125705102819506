<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
      <v-dialog
          v-model="dialog"
          width="600"
          height="500"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              color="blue"
              style="margin-top:6px;"
              class="float-end"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-plus"
          >
            Dodaj nową zmienną
          </v-btn>

        </template>
        <v-card>
          <v-card-text>
            <v-form>
              <v-text-field
                  label="Nazwa"
                  density="compact"
                  v-model="title"
                  hide-details="auto"
                  class="mb-5"
                  counter
                  maxlength="20"
              ></v-text-field>
              <v-text-field
                  label="Wartość"
                  density="compact"
                  v-model="value"
                  hide-details="auto"
                  class="mb-5"
                  counter
                  maxlength="20"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn color="blue" @click="fetchAddVariable(itemId)">Zapisz</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="secondary" @click="dialog = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-col>
  </v-row>


  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          single-line
          hide-details
          density="compact"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        density="compact"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="variables.variables"
        :loading="loading"
        :search="search"
        class="elevation-1"
        item-value="id"
        @update:options="getList"
    >

      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.selectable.id }}</td>
          <td>{{ item.selectable.title }}</td>
          <td>{{ item.selectable.value }}</td>
          <td align="end">
            <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                   @click="editItem(item.selectable)">
              Edytuj
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="red"
                   @click="fetchDeleteVariable(item.selectable.id)">
              Usuń
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>


</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

export default {
  name: 'VariablesView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      title: '',
      value: '',
      breadcrumbs: [
        {
          title: 'Zmienne',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Wartość', key: 'value', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: true,
      totalItems: 10,
      itemId: null,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken']),
    ...mapState(["variables"])
  },
  created() {
    this.fetchLoadVariables();
  },
  methods: {
    ...mapActions(['loadVariables','addVariable']),
    async fetchLoadVariables() {
      await this.loadVariables();
      this.loading = false
    },
    async fetchAddVariable(idVariable) {
     //console.log('fetchAddVariable',idVariable);
      if(idVariable){
        await this.$store.dispatch('updateVariable', {item: {title: this.title, value: this.value}, idVariable: idVariable});
      }else{
        await this.$store.dispatch('addVariable', {title: this.title, value: this.value});
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if(this.$store.state.users.responseStatus === 'success'){
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteVariable(idVariable) {
      await this.$store.dispatch('removeVariable', idVariable);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(variable) {
      this.dialog = true
      this.title = variable.title
      this.value = variable.value
      this.itemId = variable.id
    },
  }
}
</script>
