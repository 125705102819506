<template>

  <v-container text-center>
    <v-row justify="center" align="center" class="h-100">
      <v-col cols="4" sm="12" lg="4" align-self="center">
        <v-card>
          <v-card-title class="text-center">
            <v-img
                :width="300"
                aspect-ratio="16/9"
                class="mt-3 mx-auto"
                cover
                src="programigo.png"
            ></v-img>
          </v-card-title>
          <v-divider class="my-5"></v-divider>
          <v-card-text v-if="lostPassword === false">
            <v-form>
              <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Email"
                  type="text"
                  v-model="email"
                  variant="outlined"
                  :rules="emailRules"
              ></v-text-field>
              <v-text-field
                  id="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  :rules="passwordRules"
                  v-model="password"
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-checkbox label="Zapamiętaj mnie" hide-details></v-checkbox>
                </v-col>
                <v-col class="text-end">
                  <v-btn variant="flat" class="mt-3" @click="lostPassword = !lostPassword">Zapomniałeś hasła ?</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text v-if="lostPassword === true">
            <v-form>
              <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Email"
                  type="text"
                  v-model="email"
                  :rules="emailRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text v-if="errorForm !== ''">
            <v-alert :text="errorForm" type="error"></v-alert>
          </v-card-text>
          <v-card-text class="text-center" v-if="lostPassword === false">
            <v-btn color="primary" block class="mx-auto" variant="elevated" size="x-large" @click="login">Zaloguj
            </v-btn>
          </v-card-text>
          <v-card-text class="text-center" v-if="lostPassword === true">
            <v-btn color="primary" block class="mx-auto" variant="elevated" size="x-large" @click="remindPassword">Przypomnij hasło
            </v-btn>
            <v-btn variant="flat" class="mt-3" @click="lostPassword = !lostPassword">Zaloguj się</v-btn>
          </v-card-text>
          <v-divider class="mt-5"></v-divider>
          <v-card-text class="text-center">
            Nie masz jeszcze konta?
            <v-btn color="secondary" @click="login" class="mx-5">Zarejestruj się</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
import {mapMutations} from "vuex";
import axios from "axios";

export default {
  data: () => {
    return {
      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },
      lostPassword: false,
      errorForm: "",
      email: "",
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail jest wymagany.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail musi być prawidłowy'
        },
      ],
      password: "",
      passwordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
      ],

    };
  },
  methods: {
    ...mapMutations(["setToken", "setUser", "setRoles", "logout"]),
    async login(e) {
      e.preventDefault();
      const response = await fetch("https://api.gh.programigo.com/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      });

      if (response.status === 401) {
        this.errorForm = "Nieprawidłowy adres email lub hasło";
      } else {
        const {token} = await response.json();
        this.setToken(token);
        this.setUser(this.email);


        await axios.get("https://api.gh.programigo.com/api/current-user",{
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
            .then(response => {

             //console.log(response);
              this.setRoles(response.data.roles);
            })
            .catch(() => {
             //console.log(error);
              this.logout;
            });


      }
    },
    remindPassword(){

      axios.post('https://api.gh.programigo.com/reset-password',{
        email: this.email
      },)
          .then(response => {
            this.$notify({
              title: response.data,
              type: 'success',
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify({
              title: error['hydra:description'],
              type: 'error',
            });
          });
    }
  },
};
</script>
<style>
.login-form {
  max-width: 500px;
}

</style>
