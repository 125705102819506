import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import UsersView from "@/views/users/UsersView.vue";
import UserProfileView from "@/views/users/UserProfileView.vue";
import UnitsView from "@/views/units/UnitsView.vue";
import ProductsView from "@/views/products/ProductsView.vue";
import TicketsSingleView from "@/views/tickets/TicketsSingleView.vue";
import TicketsView from "@/views/tickets/TicketsView.vue";
import FeaturesView from "@/views/features/FeaturesView.vue";
import ProductsTreeView2 from "@/views/tree/ProductsTreeView2.vue";
import ProjectsSingleView from "@/views/projects/ProjectsSingleView.vue";
import ProjectsView from "@/views/projects/ProjectsView.vue";
import ProjectsElementsView from "@/views/projects/ProjectsElementsView.vue";
import ProductsReadyView from "@/views/products/ProductsReadyView.vue";
import CategoriesView from "@/views/categories/CategoriesView.vue";
import VariablesView from "@/views/variables/VariablesView.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView
  },
  {
    path: '/user/profile/:id*',
    name: 'userProfile',
    component: UserProfileView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  /* */

  {
    path: '/products/',
    name: 'products',
    component: ProductsView
  },

  {
    path: '/products/tree2/:id*',
    name: 'productsTree2',
    component: ProductsTreeView2
  },
  {
    path: '/products/ready',
    name: 'productsReady',
    component: ProductsReadyView
  },
  {
    path: '/project/:id*',
    name: 'project',
    component: ProjectsSingleView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView
  },

  {
    path: '/projects/element',
    name: 'elements',
    component: ProjectsElementsView
  },

  {
    path: '/ticket/:id*',
    name: 'ticket',
    component: TicketsSingleView
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TicketsView
  },
  {
    path: '/categories',
    name: 'categories',
    component: CategoriesView
  },
  {
    path: '/features',
    name: 'features',
    component: FeaturesView
  },
  {
    path: '/units',
    name: 'units',
    component: UnitsView
  },

  {
    path: '/variables',
    name: 'variables',
    component: VariablesView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
