import { createStore } from 'vuex'
import { useStorage } from "vue3-storage";
import {projects} from "./modules/projects";
import {categories} from "./modules/categories";
import {readyProducts} from "@/store/modules/readyProducts";
import {users} from "@/store/modules/users";
import {units} from "@/store/modules/units";
import {features} from "@/store/modules/features";
import {projectsElements} from "@/store/modules/projectsElements";
import {variables} from "@/store/modules/variables";
import {tickets} from "@/store/modules/tickets";
export default createStore({
  state: {
    token: null,
    user: null,
    roles: null,

  },
  getters: {

    isLoggedIn(state) {
      const storage = useStorage();
      const token = storage.getStorageSync("token");
      state.token = token;
      return !!state.token;
    },
    getToken(state) {
      const storage = useStorage();
      const token = storage.getStorageSync("token");
      return state.token = token;
    },
    getUser(state) {
      const storage = useStorage();
      const user = storage.getStorageSync("user");
      return state.user = user;
    },
    getRoles(state) {
      const storage = useStorage();
      const user = storage.getStorageSync("roles");
      return state.roles = user;
    },

  },
  mutations: {


    setToken(state, token) {
      const storage = useStorage();
      storage.setStorage({
        key: "token",
        data: token
      })
      state.token = token;
    },
    setUser(state, user) {
      const storage = useStorage();
      storage.setStorage({
        key: "user",
        data: user
      })
      state.user = user;
    },
    setRoles(state, roles) {
      const storage = useStorage();
      storage.setStorage({
        key: "roles",
        data: roles
      })
      state.roles = roles;
    },
    logout(state){
     //console.log('Usuwamy')
      const storage = useStorage();
      storage.removeStorageSync('token');
      storage.removeStorageSync('user');
      storage.removeStorageSync('roles');
      state.token = null
      return state.token;
    },
  },
  actions: {
  },
  modules: {
    users,
    units,
    features,

    projects,
    projectsElements,
    readyProducts,
    categories,
    variables,
    tickets

  }
})
