<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs" />
    </v-col>
    <v-col>
      <v-btn
          color="red"
          style="margin-top:6px;"
          class="float-end mx-2"
          size="x-small"
          prepend-icon="mdi-format-list-bulleted-square"
          to="/users"
      >
        Lista użytkowników
      </v-btn>
    </v-col>
  </v-row>

  <v-card v-if="loading === false">
    <v-tabs v-model="tab">
      <v-tab value="one">
        <v-icon left> mdi-account-cog </v-icon>
        Edytuj profil
      </v-tab>
      <v-tab value="two">
        <v-icon left> mdi-lock </v-icon>
        Uprawnienia
      </v-tab>
      <v-tab value="three">
        <v-icon left> mdi-cog </v-icon>
        Ustawienia
      </v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="one">
          <v-row>
            <v-col>
              <v-text-field
                  prepend-icon="mdi-email"
                  name="email"
                  label="Email"
                  density="compact"
                  type="text"
                  v-model="email"
                  :rules="emailRules"
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field
                      prepend-icon="mdi-email"
                      name="name"
                      label="Imię"
                      density="compact"
                      type="text"
                      v-model="firstName"
                      :rules="firstNameRules"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      prepend-icon="mdi-email"
                      name="lastName"
                      label="Nazwisko"
                      density="compact"
                      type="text"
                      v-model="lastName"
                      :rules="lastNameRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-textarea label="Opis" v-model="description"></v-textarea>
              <v-btn variant="flat" color="primary"  @click="saveUser">Zapisz</v-btn>
            </v-col>
            <v-col class="text-center">
              <v-avatar image="team.png" size="200"></v-avatar>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item value="two">
          <v-table density="compact">
            <thead>
            <tr>
              <th colspan="2">Główne uprawnienia</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="role_super_admin"
                         :checked="roles.indexOf('ROLE_SUPER_ADMIN')> -1"
                         @change="updateRole('ROLE_SUPER_ADMIN')">
                  <label class="form-check-label" for="role_super_admin">
                    Super Administrator
                  </label>
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="role_admin"
                         :checked="roles.indexOf('ROLE_ADMIN')> -1"
                         @change="updateRole('ROLE_ADMIN')">
                  <label class="form-check-label" for="role_admin">
                    Administrator
                  </label>
                </div>
              </td>
            </tr>
            </tbody>

          </v-table>

          <v-table density="compact">
            <thead>
            <tr>
              <th colspan="2">Ustawienia dodatkowe</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="role_super_admin"
                         :checked="roles.indexOf('ROLE_BUILD_TREE')> -1"
                         @change="updateRole('ROLE_BUILD_TREE')">
                  <label class="form-check-label" for="role_super_admin">
                    Tworzenie drzewa produktów
                  </label>
                </div>
              </td>
              <td>
              </td>
            </tr>
            </tbody>

          </v-table>



          <v-table density="compact">
            <thead>
            <tr>
              <th class="text-left">
                Sekcja
              </th>
              <th class="text-left">
                Odczyt
              </th>
              <th class="text-left">
                Zapis
              </th>
              <th class="text-left">
                Usuwanie
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Zlecenia</td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_TICKETS_READ')> -1"
                         @change="updateRole('ROLE_TICKETS_READ')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_TICKETS_SAVE')> -1"
                         @change="updateRole('ROLE_TICKETS_SAVE')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_TICKETS_DELETE')> -1"
                         @change="updateRole('ROLE_TICKETS_DELETE')">
                </div>
              </td>
            </tr>
            <tr>
              <td>Produkty</td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_PRODUCTS_READ')> -1"
                         @change="updateRole('ROLE_PRODUCTS_READ')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_PRODUCTS_SAVE')> -1"
                         @change="updateRole('ROLE_PRODUCTS_SAVE')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_PRODUCTS_DELETE')> -1"
                         @change="updateRole('ROLE_PRODUCTS_DELETE')">
                </div>
              </td>
            </tr>
            <tr>
              <td>Typy</td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_TYPES_READ')> -1"
                         @change="updateRole('ROLE_TYPES_READ')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_TYPES_SAVE')> -1"
                         @change="updateRole('ROLE_TYPES_SAVE')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_TYPES_DELETE')> -1"
                         @change="updateRole('ROLE_TYPES_DELETE')">
                </div>
              </td>
            </tr>
            <tr>
              <td>Cechy</td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_FEATURES_READ')> -1"
                         @change="updateRole('ROLE_FEATURES_READ')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_FEATURES_SAVE')> -1"
                         @change="updateRole('ROLE_FEATURES_SAVE')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_FEATURES_DELETE')> -1"
                         @change="updateRole('ROLE_FEATURES_DELETE')">
                </div>
              </td>
            </tr>
            <tr>
              <td>Jednostki</td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_UNITS_READ')> -1"
                         @change="updateRole('ROLE_UNITS_READ')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_UNITS_SAVE')> -1"
                         @change="updateRole('ROLE_UNITS_SAVE')">
                </div>
              </td>
              <td>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox"
                         :checked="roles.indexOf('ROLE_UNITS_DELETE')> -1"
                         @change="updateRole('ROLE_UNITS_DELETE')">
                </div>
              </td>
            </tr>
            <tr>
              <td>Użytkownicy</td>
              <td>
                <div class="form-check">
                <input class="form-check-input" type="checkbox"
                       :checked="roles.indexOf('ROLE_USERS_READ')> -1"
                       @change="updateRole('ROLE_USERS_READ')">
                </div>
              </td>
              <td>
                <div class="form-check">
                <input class="form-check-input" type="checkbox"
                       :checked="roles.indexOf('ROLE_USERS_SAVE')> -1"
                       @change="updateRole('ROLE_USERS_SAVE')">
                </div>
              </td>
              <td>
                <div class="form-check">
                <input class="form-check-input" type="checkbox"
                       :checked="roles.indexOf('ROLE_USERS_DELETE')> -1"
                       @change="updateRole('ROLE_USERS_DELETE')">
                </div>
              </td>
            </tr>


            </tbody>
          </v-table>
          <v-btn variant="flat" color="primary" @click="saveUser">Zapisz</v-btn>

        </v-window-item>

        <v-window-item value="three">
          <v-row>
            <v-col cols="6">
              <v-text-field
                  id="newPassword"
                  prepend-icon="mdi-lock"
                  label="Nowe hasło"
                  type="password"
                  density="compact"
                  :rules="newPasswordRules"
                  v-model="newPassword"
              ></v-text-field>
              <v-text-field
                  id="repeatPassword"
                  prepend-icon="mdi-lock"
                  label="Powtórz nowe hasło"
                  type="password"
                  density="compact"
                  :rules="repeatPasswordRules"
                  v-model="repeatPassword"
              ></v-text-field>
              <v-alert v-show="!isPasswordMatch" text="Podane hasła są różne" class="my-4" type="error"></v-alert>
            </v-col>
          </v-row>
          <v-btn variant="flat" color="primary" @click="resetPassword">Zapisz</v-btn>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
  <div v-else-if="loading === true">
    <div class="text-center">
      <v-progress-circular
          indeterminate
          color="red"
          :size="70"
          :width="7"
      ></v-progress-circular>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import { mapGetters } from "vuex";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
export default {
  name: 'UserProfileView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      roles: null,
      user: null,
      breadcrumbs: [
        {
          title: 'Użytkownicy',
          disabled: false,
          to: '/users',
        },
        {
          title: 'Profil użytkownika',
          disabled: true,
        },
      ],
      tab: null,
      loading:true,
      userData: null,
      description: null,
      isPasswordMatch: true,

      email: "",
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail jest wymagany.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail musi być prawidłowy'
        },
      ],
      firstName: "",
      firstNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],
      lastName: "",
      lastNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],
      newPassword: "",
      newPasswordRules: [
        value => {
          if (value) return true
          return 'Nowe Hasło jest wymagane.'
        },
      ],
      repeatPassword: "",
      repeatPasswordRules: [
        value => {
          if (value) return true
          return 'Nowe Hasło jest wymagane.'
        },

      ],
    };
  },
  computed: {
    ...mapGetters(['getToken'])
  },
  watch: {
    repeatPassword() {
      if(this.newPassword !== this.repeatPassword){
        this.isPasswordMatch = false
      }else{
        this.isPasswordMatch = true
      }
    }
  },
  mounted() {
    this.getUserData();
  },
  methods:{
    async getUserData(){
      if (this.$route.params.id !== "") {

        axios.get("https://api.gh.programigo.com/api/users/" + this.$route.params.id,{
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        })
            .then(response => {
              this.user = response.data;
              this.email = response.data.email;
              this.firstName = response.data.firstName;
              this.lastName = response.data.lastName;
              this.description = response.data.description;
              this.user = response.data;
              this.roles = this.user.roles;
            })
            .catch(error => {
              this.$notify({
                title: error,
                type: 'error',
              });
              this.$router.go(-1)
            });

        this.loading = false;
      }else{
        this.$router.go(-1)
      }

    },
    resetPassword() {
      if (this.newPassword !== this.repeatPassword) {
        this.isPasswordMatch = false;
        return;
      }

      this.isPasswordMatch = true;

          axios.post('https://api.gh.programigo.com/reset-password/new-password',{
            newPassword: this.newPassword
          },{
            headers: {
              Authorization: `Bearer ${this.getToken}`
            }
          })
          .then(response => {
            this.items = response.data;
            this.$notify({
              title: 'Hasło zostało zmienione',
              type: 'success',
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify({
              title: error['hydra:description'],
              type: 'error',
            });
          });

      // Call your API endpoint to reset the password
      // Pass the newPassword value to the API

      // Reset the input fields
      this.newPassword = '';
      this.repeatPassword = '';

      alert('Password reset successfully');
    },
    updateRole (controller) {
      let find = this.roles.find((el) => el === controller)
      if(find){
        let index = this.roles.indexOf(find);
        if (index > -1) {
          this.roles.splice(index, 1);
        }
      }else{
        this.roles.push(controller)
      }
    },
    saveUser(){
      axios.put('https://api.gh.programigo.com/api/users/'  + this.$route.params.id,{
        roles: this.roles,
        firstName: this.firstName,
        lastName: this.lastName,
        description: this.description
      },{
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      })
          .then(response => {
            this.items = response.data;
            this.$notify({
              title: 'Zminay zostały zapisane',
              type: 'success',
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify({
              title: error['hydra:description'],
              type: 'error',
            });
          });
    }
  }
}
</script>
