<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
      <v-btn
          color="red"
          style="margin-top:6px;"
          class="float-end ms-1"
          v-bind="props"
          size="x-small"
          prepend-icon="mdi-format-list-bulleted-square"
          to="/products"
      >Wszystkie produkty
      </v-btn>

      <v-btn
          color="blue"
          class="float-end ms-1"
          style="margin-top:6px;"
          size="x-small"
          @click="newItem"
          prepend-icon="mdi-plus">
        Dodaj nowy produkt
        <v-dialog
            v-model="dialogProductNew"
            activator="parent"
            width="1000"
        >

          <v-card>
            <v-card-title>
              <v-btn icon @click="dialogProductNew = false" class="float-end" variant="text" density="compact">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <ProductsSingleDialogView :productId="null"></ProductsSingleDialogView>
          </v-card>
        </v-dialog>
        <v-tooltip
            activator="parent"
            location="end"
        >Edytuj
        </v-tooltip>
      </v-btn>

      <!--      <v-btn
                color="blue"
                style="margin-top:6px;"
                class="float-end ms-1"
                v-bind="props"
                size="x-small"
                prepend-icon="mdi-plus"
                @click="newData()"
                to="/product"
            >Dodaj nowy produkt
            </v-btn>-->
      <v-dialog
          v-model="dialog"
          width="600"
          height="500"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              color="green"
              style="margin-top:6px;"
              class="float-end ms-1"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-import"
              :loading="loadingExcel"
          >
            Import produktów
          </v-btn>
        </template>
        <v-card>
          <form @submit="uploadFile">
            <v-card-text>
              <input type="file" ref="fileInput"/>
            </v-card-text>
            <v-card-item>
              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <v-btn color="blue" type="submit">Wgraj</v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn color="secondary" @click="dialog = false">Zamknij</v-btn>
                </v-col>
              </v-row>
            </v-card-item>
          </form>
        </v-card>
      </v-dialog>
      <v-btn
          color="green"
          style="margin-top:6px;"
          class="float-end ms-1"
          v-bind="props"
          size="x-small"
          prepend-icon="mdi-export"
          href="https://api.gh.programigo.com/api/c/products/exportExcel"
          target="_blank"
      >
        Export produktów EXCEL
      </v-btn>
    </v-col>
  </v-row>
  <v-card>
    <v-card-item>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          variant="outlined"
          single-line
          density="compact"
          hide-details
          class="mb-4"
      ></v-text-field>

      <v-data-table
          :group-by="groupBy"
          :headers="headers"
          :items="readyProducts.readyProducts"
          :items-length="totalProducts"
          item-value="title"
          item-key="id"
          :loading="loading"
          :search="search"
          loading-text="Loading... Please wait"
          density="compact"
          v-model:items-per-page="itemsPerPage"
      >

        <template v-slot:[`item.actions`]="item">
          <v-btn
              color="blue"
              class="float-end ms-1"
              size="x-small"
              prepend-icon="mdi-pencil">

            <v-dialog
                v-model="dialogProduct[item.item.selectable.id]"
                activator="parent"
                width="1000"
            >
              <v-card>
                <v-card-title>
                  ID: {{ item.item.selectable.id }}
                  <v-btn icon @click="dialogProduct[item.item.selectable.id] = false" class="float-end" variant="text"
                         density="compact">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <ProductsSingleDialogView v-if="dialogProduct[item.item.selectable.id] === true"
                                          :productId="item.item.selectable.id"></ProductsSingleDialogView>
              </v-card>
            </v-dialog>
            <v-tooltip
                activator="parent"
                location="end"
            >Edytuj
            </v-tooltip>
          </v-btn>
          <v-btn class="float-end ms-1" prepend-icon="mdi-content-copy" size="x-small" fab dark color="orange"
                 @click="copyById(item.item.selectable.id)">
            <v-tooltip
                activator="parent"
                location="end"
            >Kopiuj
            </v-tooltip>
          </v-btn>

          <v-btn class="float-end ms-1" prepend-icon="mdi-trash-can" size="x-small" fab dark color="red"
                 @click="removeItem(item.item.selectable.id)">
            <v-tooltip
                activator="parent"
                location="end"
            >Usuń
            </v-tooltip>
          </v-btn>
        </template>


        <template v-slot:[`item.price`]="price">
          <v-row v-if="cell[price.item.selectable.id] === true">
            <v-col>
              <v-text-field
                  class="w-100 p-0 mt-2"
                  light
                  density="compact"
                  required
                  variant="outlined"
                  v-model="prices"
                  :value="prices"
                  hide-details="auto"
                  @keyup.enter="savePrice(price.item.selectable.id)"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn class="mx-2 my-3" icon="mdi-content-save" size="x-small" fab dark color="blue"
                     @click="savePrice(price.item.selectable.id)">
              </v-btn>
            </v-col>
          </v-row>
          <span @click="editCell(price.item.selectable.id)"
                v-if="!cell[price.item.selectable.id] || cell[price.item.selectable.id] === false">
              <v-row>
                <v-col>{{ price.item.selectable.currency }} </v-col>
                <v-col>{{ price.item.selectable.price }}</v-col>
              </v-row>
              </span>
        </template>

        <template v-slot:[`item.priceUnit`]="price">
          <v-row v-if="cell[price.item.selectable.id] === true">
            <v-col>
              <v-text-field
                  class="w-100 p-0 mt-2"
                  light
                  density="compact"
                  required
                  variant="outlined"
                  v-model="pricesUnit"
                  :value="pricesUnit"
                  hide-details="auto"
                  @keyup.enter="savePrice(price.item.selectable.id)"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn class="mx-2 my-3" icon="mdi-content-save" size="x-small" fab dark color="blue"
                     @click="savePrice(price.item.selectable.id)">
              </v-btn>
            </v-col>
          </v-row>
          <span @click="editCell(price.item.selectable.id)"
                v-if="!cell[price.item.selectable.id] || cell[price.item.selectable.id] === false">{{
              price.item.selectable.priceUnit
            }}</span>
        </template>

      </v-data-table>
    </v-card-item>
  </v-card>
</template>
<script>
import axios from "axios";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

import {mapActions, mapGetters, mapState} from "vuex";
import ProductsSingleDialogView from "@/views/products/ProductsSingleDialogView.vue";


export default {
  name: "ProductsReadyView",
  components: {ProductsSingleDialogView, BreadcrumbsComponent},
  data() {
    return {
      breadcrumbs: [
        {
          title: 'Produkty handlowe',
          disabled: true,
        },
      ],

      groupBy: [{key: 'category.title', order: 'asc'}],
      loading: true,


      dialogProduct: [],
      dialogProductNew: false,

      text: '',
      items: [],

      loadingExcel: false,
      prices: [],
      pricesUnit: [],

      expanded: [],
      singleExpand: false,

      selectedEmployees: [],
      isSelectionModeDisabled: true,
      isRecursiveDisabled: true,
      selectNodesRecursiveValue: false,
      selectByClickValue: true,
      selectionModeValue: 'multiple',

      meanUnitData: [],
      feature: [],
      categories: [],
      category: null,
      selectCategory: null,
      media: {
        saved: [],
        added: [],
        removed: []
      },
      products: {},
      product: null,

      searchMode: 'contains',
      searchValue: '',
      searchValue2: '',

      meanUnit: [
        value => !!value || 'Wymagane.',
        value => (value && value.length >= 1) || 'Min 1 znak',
      ],
      features: {},
      dialog: false,
      selectedFeatures: [],
      search: '',
      itemsPerPage: 100,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Znaczniki', key: 'tags', align: 'start'},
        {title: 'Cena', key: 'price', align: 'start'},
        {title: 'Cena JM', key: 'priceUnit', align: 'start'},
        {title: 'JM', key: 'unit.title', align: 'start'},
        {title: 'EAN', key: 'ean', align: 'start'},
        {title: 'Akcja', key: 'actions', align: 'end', sortable: false},
        {title: '', key: 'data-table-expand'},
      ],
      totalItems: 1000,
      itemId: null,
      title: '',
      id: null,
      description: '',
      server: 'https://api.gh.programigo.com/api/c/products/addImages/',
      cell: [],
      file: null,
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "totalProducts"]),
    ...mapState(["readyProducts"]),
  },
  methods: {
    ...mapActions(['loadReadyProducts','copyProduct']),
    async fetchReadyProducts() {
      await this.loadReadyProducts();
      this.loading = false
    },


    loadDetails({item}) {
      item.details = 'nono';
    },

    savePrice(e) {
      let item = this.readyProducts.readyProducts.find((el) => el.id === e);
      let price = this.prices.toString();
      let priceUnit = this.pricesUnit.toString();
      item.price = parseFloat(price.replace(/,/g, '.'));
      item.priceUnit = parseFloat(priceUnit.replace(/,/g, '.'));


      axios.put('https://api.gh.programigo.com/api/products/price/' + item.id, {
        price: parseFloat(this.prices),
        priceUnit: parseFloat(this.pricesUnit)
      }, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      }).then(() => {
        // Obsłuż odpowiedź API
       //console.log(response.data);
        this.$notify({
          title: "Cena została zaktualizowana",
          type: 'success',
        });

      })

      this.cell[e] = false;
    },
    editCell(e) {
      let item = this.readyProducts.readyProducts.find((el) => el.id === e);
      this.prices = item.price
      this.pricesUnit = item.priceUnit
      this.cell = [];
      this.cell[e] = true;
    },

    newData() {
      this.id = null
      this.title = ''
      this.description = ''
      this.selectCategory = ''
      this.meanUnitData = []
      this.features = {}
      this.category = {}
      this.selectedFeatures = []
      this.feature = []
    },

    newItem() {
      this.$store.state.readyProducts.currentProduct = null;
    },
    getById(e) {
      this.$router.push({name: 'product', params: {id: e}})
    },
    async copyById(e) {
      axios.get('https://api.gh.programigo.com/api/products/clone/' + e, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      })
          .then(response => {


             this.copyProduct(response.data)


            this.dialogProduct[response.data.id] = true
          });
    },
      getList()
      {
        this.loading = true
        axios.get('https://api.gh.programigo.com/api/products/ready', {
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        })
            .then(response => {
              const json = response.data;
              this.readyProducts = json['hydra:member'];
              this.loading = false
            });
      }
    ,

      removeItem(e)
      {
        axios.get('https://api.gh.programigo.com/api/c/products/removeItem/' + e)
            .then(() => {
             //console.log(response)
              this.getList();
            });
      }
    ,
      handleExpansion(item, state)
      {
        const itemIndex = this.expanded.indexOf(item);

        state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
      }
    ,
      uploadFile(event)
      {
        this.loadingExcel = true;
        event.preventDefault();

        const file = this.$refs.fileInput.files[0];
        const formData = new FormData();
        formData.append('file', file);

        this.dialog = false;

        this.$notify({
          title: "Plik został przesłany. Trwa aktualizacja",
          type: 'success',
        });


        // Wykonaj żądanie API przy użyciu preferowanej metody (np. Axios)
        axios.post('https://api.gh.programigo.com/api/c/products/importExcel/', formData)
            .then(() => {
              // Obsłuż odpowiedź API
             //console.log(response.data);
              this.$notify({
                title: "Aktualizacja przebiegła pomyślnie",
                type: 'success',
              });
              this.getList();
              this.loadingExcel = false;
            })
            .catch(error => {
              // Obsłuż ewentualne błędy
              console.error(error);
              this.$notify({
                title: "Wystąpił problem podczas aktualizacji produktów",
                type: 'error',
              });
              this.loadingExcel = false;
            });
      }
    },
    created() {
      this.fetchReadyProducts();
    }
  }
</script>

<style>
table td {
  font-size: 10px;
}

table .v-field__input {
  padding: 5px;
  font-size: 10px;
  min-height: auto;
}

table .v-btn--icon.v-btn--density-default {
  width: 20px;
  height: 20px;
}

table .mdi:before {
  font-size: 11px !important;
}

.v-table__wrapper {
  border-radius: inherit;
  overflow: hidden;
  padding-bottom: 5px;
}

</style>
