<template>
  <notifications position="bottom right" />



<LoginView v-if="!isLoggedIn" />
  <v-app id="inspire" v-if="isLoggedIn">
    <v-navigation-drawer v-model="drawer">
      <v-list density="compact" nav v-model:opened="open">
        <v-list-item prepend-icon="mdi-home"  title="Strona głowna" value="home" to="/"></v-list-item>
        <v-divider></v-divider>
        <v-list-item prepend-icon="mdi-list-box-outline"  title="Projekty" value="projekty" to="/projects" v-if="checkRole(['ROLE_TICKETS_READ','ROLE_ADMIN'])"></v-list-item>
        <v-list-item prepend-icon="mdi-list-box-outline"  title="Elementy" value="elementy" to="/projects/element" v-if="checkRole(['ROLE_TICKETS_READ','ROLE_ADMIN'])"></v-list-item>
<!--        <v-list-item prepend-icon="mdi-file-tree-outline"  title="Nowy projekt" value="gantt" to="/products/tree"></v-list-item>-->
        <v-list-item prepend-icon="mdi-file-tree-outline"  title="Nowy projekt" value="gantt" to="/products/tree2"></v-list-item>
        <v-divider></v-divider>
        <v-list-item prepend-icon="mdi-list-box-outline"  title="Zlecenia" value="zlecenia" to="/tickets" v-if="checkRole(['ROLE_TICKETS_READ','ROLE_ADMIN'])"></v-list-item>
        <!--        <v-list-item prepend-icon="mdi-file-tree-outline"  title="Nowy projekt" value="gantt" to="/products/tree"></v-list-item>-->
        <v-list-item prepend-icon="mdi-file-tree-outline"  title="Nowe zlecenie" value="gantt" to="/ticket"></v-list-item>
        <v-divider></v-divider>

        <v-list-item prepend-icon="mdi-package-variant-closed"  title="Produkty własne" value="home" to="/products" v-if="checkRole(['ROLE_PRODUCTS_READ','ROLE_ADMIN'])"></v-list-item>
        <v-list-item prepend-icon="mdi-package-variant-closed"  title="Produkty handlowe" value="home" to="/products/ready" v-if="checkRole(['ROLE_PRODUCTS_READ','ROLE_ADMIN'])"></v-list-item>
        <v-list-group value="Ustawienia"
                      v-if="checkRole(['ROLE_TYPES_READ','ROLE_FEATURES_READ','ROLE_UNITS_READ','ROLE_USERS_READ','ROLE_ADMIN'])"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
                v-bind="props"
                prepend-icon="mdi-cog"
                title="Ustawienia"
            ></v-list-item>
          </template>

          <v-list-item  title="Zmienne" value="types" to="/variables" v-if="checkRole(['ROLE_VARIABLES_READ','ROLE_ADMIN'])"></v-list-item>
          <v-list-item  title="Kategorie" value="types" to="/categories" v-if="checkRole(['ROLE_TYPES_READ','ROLE_ADMIN'])"></v-list-item>
          <v-list-item  title="Cechy" value="features" to="/features"  v-if="checkRole(['ROLE_FEATURES_READ','ROLE_ADMIN'])"></v-list-item>
          <v-list-item  title="Jednostki" value="units" to="/units"  v-if="checkRole(['ROLE_UNITS_READ','ROLE_ADMIN'])"></v-list-item>
          <v-list-item  title="Użytkownicy" value="users" to="/users"  v-if="checkRole(['ROLE_USERS_READ','ROLE_ADMIN'])"></v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>PROGRAMIGO</v-toolbar-title>
      <v-btn icon="mdi-theme-light-dark" @click="toggleTheme">
      </v-btn>
      <v-spacer></v-spacer>
      {{getUser}}


      <v-btn @click="logout" append-icon="mdi-logout"  class="">
        Wyloguj
      </v-btn>

    </v-app-bar>

    <v-main>
      <section class="v-container v-container--fluid pt-2">
        <router-view :key="$route.fullPath"/>
      </section>
    </v-main>
  </v-app >
</template>
<script>
import { useTheme } from 'vuetify'
import {mapGetters, mapMutations } from "vuex";

import LoginView from "@/views/LoginView.vue";
export default {
  name: "App",
  components: {LoginView},
  computed: {
    ...mapGetters(["isLoggedIn",'getToken',"getUser","getRoles"])
  },
  data: () => ({
    drawer: null,
    open: ['Users'],
    admins: [
      ['Management', 'mdi-account-multiple-outline'],
      ['Settings', 'mdi-cog-outline'],
    ],
    cruds: [
      ['Create', 'mdi-plus-outline'],
      ['Read', 'mdi-file-outline'],
      ['Update', 'mdi-update'],
      ['Delete', 'mdi-delete'],
    ],
    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me 2' },
    ],
  }),
  setup () {
    const theme = useTheme()
    return {
      theme,
      toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }
  },

  methods:{
    ...mapMutations(["logout","checkRole"]),
    checkRole(){
      //console.log(e)
      return true;
      //let _return = true;
/*      if(this.getRoles !== undefined){
        e.forEach((o) => {
          _return =  (this.getRoles.find((q) => q === o) !== undefined);
        })
        return _return;
      }*/
    }
  }
}
</script>
<style scoped>

</style>
