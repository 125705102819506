<template>
  <h1>Dashboardsfd</h1>
</template>


<script>
// @ is an alias to /src

import { mapGetters } from "vuex";
export default {
  name: 'HomeView',
  data: () => {
    return {
      users: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn",'getToken'])
  },
}
</script>
