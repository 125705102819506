<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
      <v-btn
          color="red"
          style="margin-top:6px;"
          class="float-end mx-2"
          v-bind="props"
          size="x-small"
          prepend-icon="mdi-format-list-bulleted-square"
          to="/products/ready"
      >
        Produkty handlowe
      </v-btn>
    </v-col>
  </v-row>


  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          single-line
          hide-details
          density="compact"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        density="compact"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="items"
        :loading="loading"
        :search="search"
        class="elevation-1"
        item-value="id"
        @update:options="getList"
    >

      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.selectable.id }}</td>
          <td>{{ item.selectable.title }}</td>
          <td>{{ item.selectable.price }}</td>
          <td>{{ item.selectable.category ? item.selectable.category.title : '' }}</td>
          <td align="end">
            <v-btn class="mx-2" append-icon="mdi-content-copy" size="x-small" fab dark color="orange"
                   @click="copyById(item.selectable.id)">
              Duplikuj
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                   @click="getById(item.selectable.id)">
              Edytuj
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="red"
                   @click="removeItem(item.selectable.id)">
              Usuń
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>
  </v-card>


</template>


<script>
// @ is an alias to /src

import {mapGetters} from "vuex";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import axios from "axios";

export default {
  name: 'ProductsView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {

      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      title: '',

      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: true,
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Cena', key: 'price', align: 'start'},
        {title: 'Kategoria', key: 'category', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: true,
      totalItems: 10,
      itemId: null,
      items: [],

    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken'])
  },
  mounted() {
    this.getList();
  },
  methods: {
    copyById(e) {
      axios.get('https://api.gh.programigo.com/api/c/products/cloneById/' + e)
          .then(response => {
            this.$router.push({name: 'product', params: {id: response.data}})
          });
    },
    async getList() {
      axios.get('https://api.gh.programigo.com/api/products/build', {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.getToken}`
        }
      }).then(response => {
        this.items = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.$notify({
          title: error,
          type: 'error',
        });
      });
    },
    saveItem(e) {
      if (e !== null) {
        axios.put('https://api.gh.programigo.com/api/products/' + e, {
          title: this.title,
        }, {
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        })
            .then(() => {
             //console.log(response);
              this.$notify({
                title: 'Pozycja została dodana',
                type: 'success',
              });
              this.getList();
              this.dialog = false;
            })
            .catch(error => {
              console.error(error);
              this.$notify({
                title: error['hydra:description'],
                type: 'error',
              });
            });
      }else{
        axios.post('https://api.gh.programigo.com/api/products', {
          title: this.title,
        }, {
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        })
            .then(() => {
             //console.log(response);
              this.$notify({
                title: 'Pozycja została dodana',
                type: 'success',
              });
              this.getList();
              this.dialog = false;
            })
            .catch(error => {
              console.error(error);
              this.$notify({
                title: error['hydra:description'],
                type: 'error',
              });
            });
      }

    },
    editItem(e) {
      this.dialog = true
      this.title = e.title
      this.itemId = e.id
    },
    removeItem(e) {
      this.loading = true
      axios.delete('https://api.gh.programigo.com/api/products/' + e, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      })
          .then(() => {
           //console.log(response)
            this.$notify({
              title: 'Poycja została usunięta',
              type: 'success',
            });
            this.getList();
          })
          .catch(error => {
            console.error(error);
            this.$notify({
              title: error,
              type: 'error',
            });
          });
      this.loading = false;
    },
    /* */
    getById(e) {
      this.$router.push({name: 'product', params: {id: e}})
    },

  }
}
</script>
