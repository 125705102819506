<template>
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
      <v-dialog
          v-model="dialog"
          width="1000"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              color="blue"
              style="margin-top:6px;"
              class="float-end"
              v-bind="props"
              size="x-small"
              prepend-icon="mdi-plus"
          >
            Dodaj kategorię
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                    density="compact"
                    label="Typ"
                    :rules="[rules.required, rules.counter]"
                    v-model="title"
                    hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-checkbox
                    density="compact"
                    :label="feature.title"
                    v-for="feature in features.features"
                    :key="feature.id"
                    v-model="selectedFeatures"
                    :value="feature"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn color="blue" @click="fetchAddCategories(itemId)">Zapisz</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="secondary" @click="dialog = false">Zamknij</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>

  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Szukaj"
          single-line
          hide-details
          density="compact"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        density="compact"
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items-length="totalItems"
        :items="categories.categories"
        :loading="loading"
        :search="search"
        class="elevation-1"
        item-value="id"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.selectable.id }}</td>
          <td>{{ item.selectable.title }}</td>
          <td>
            <span v-for="features in item.selectable.features" :key="features.id"> [ {{ features.title }} ] </span>
          </td>
          <td align="end">
            <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                   @click="editItem(item.selectable)">
              Edytuj
            </v-btn>
            <v-btn class="mx-2" append-icon="mdi-trash-can" size="x-small" fab dark color="red"
                   @click="fetchDeleteCategory(item.selectable.id)">
              Usuń
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

export default {
  name: 'CategoriesView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      title: '',
      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: false,
          to: 'products',
        },
        {
          title: 'Typy',
          disabled: true,
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Cechy', key: 'features', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: true,
      totalItems: 10,
      itemId: null,
      items: [],
      selectedFeatures: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken']),
    ...mapState(["categories","features"])
  },
  created() {
    this.fetchLoadCategories();
    this.fetchLoadFeatures();
  },
  methods: {
    ...mapActions(['loadCategories','loadFeatures','addCategory']),
    async fetchLoadCategories() {
      await this.loadCategories();
      this.loading = false
    },
    async fetchLoadFeatures() {
      await this.loadFeatures();
    },

    async fetchAddCategories(idCategory) {
     //console.log('fetchAddCategories', idCategory);
      if (idCategory) {
        await this.$store.dispatch('updateCategory', {
          item: {
            title: this.title,
            features: this.selectedFeatures
          }, idCategory: idCategory
        });
      } else {
        await this.$store.dispatch('addCategory', {
          title: this.title,
          features: this.selectedFeatures
        });
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
    },
    async fetchDeleteCategory(idCategory) {
      await this.$store.dispatch('removeCategory', idCategory);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(category) {
      this.dialog = true
      this.title = category.title
      this.itemId = category.id
      this.selectedFeatures = category.features;
    },

  }
}
</script>
